import * as React from 'react'

const Comment = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="#3a9b2e"
			fillRule="evenodd"
			{...props}
		>
			<path d="M21,14H7a1.278,1.278,0,0,1,0-2.545H21A1.278,1.278,0,0,1,21,14M7,6.364H17.5a1.223,1.223,0,0,1,1.167,1.273A1.223,1.223,0,0,1,17.5,8.909H7A1.223,1.223,0,0,1,5.833,7.636,1.223,1.223,0,0,1,7,6.364M24.767,0H3.233A3.393,3.393,0,0,0,0,3.527V18.11a3.393,3.393,0,0,0,3.233,3.527H3.5v5.091A1.279,1.279,0,0,0,4.22,27.9a1.089,1.089,0,0,0,.447.1,1.12,1.12,0,0,0,.825-.373l5.491-5.991H24.767A3.393,3.393,0,0,0,28,18.11V3.527A3.393,3.393,0,0,0,24.767,0" />
		</svg>
	)
}

export default Comment
