import 'styled-components'
import * as variables from './variables'

type Variables = typeof variables

/** This defines the order of the stacking context */
enum ZIndex {
	MARQUEE = 100,
	MAPS_TOOLTIP,
	RESTAURANT_CARD_LABEL,
	SEARCH_RESULTS_LIST = 200,
	RESTAURANT_LIST_SORT_OPTIONS,
	RESTAURANT_LIST_JUMP_TO_TOP_BUTTON,
	NAVIGATION_DROPDOWN_CONTENT,
	MENU_TOPLINE,
	MAIN_NAVIGATION,
	MOBILE_CART_TOGGLE = 1000000,
	GLOBAL_MARQUEE,
	MODAL = 2147483647,
	MODAL_CLOSE_BUTTON,
}

declare module 'styled-components' {
	export interface DefaultTheme extends Variables {
		colors: {
			black: string
			grayDark: string
			grayAlmostDark: string
			grayMediumDark: string
			gray: string
			grayMediumLight: string
			grayLight: string
			white: string
			orange: string
			orangeMedium: string
			orangeLight: string
			red: string
			redDark: string
			redLight: string
			pinkBright: string
			limeGreen: string
			green: string
			greenDark: string
			facebook: string
		}
		profileMaxWidth: string
		mainNavHeight: string
		buttonBorderRadius: string
		zIndex: typeof ZIndex
	}
}

export const DefaultTheme = {
	colors: {
		black: '#000',
		grayDark: '#444',
		grayAlmostDark: '#666',
		grayMediumDark: '#828585',
		gray: '#d4d4d4',
		grayMediumLight: '#eee',
		grayLight: '#f9f9f9',
		white: '#fff',
		orange: '#e89042',
		orangeMedium: '#ffb600',
		orangeLight: '#f8cc18',
		red: '#e22027',
		redDark: '#b92b2a',
		redLight: '#ed5553',
		pinkBright: '#fdeeed',
		limeGreen: '#b2d566',
		green: '#429236',
		greenDark: '#429236',
		facebook: '#3367a4',
	},
	...variables,
	profileMaxWidth: '630px',
	mainNavHeight: '64px',
	buttonBorderRadius: '5px',
	zIndex: ZIndex,
}
