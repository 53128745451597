import http, { v3Instance, v3InstanceCached, apiGateway } from './http'
import { v4 as uuid } from 'uuid'
import {
	LoginResponse,
	LogoutResponse,
	UpdateUserData,
	UserAddressListItem,
	UpdateUserAddressListItemResponse,
} from './user.types'
import {
	CreateOrderResponse,
	IOrder,
	UpdateOrderResponse,
	OrderResponse,
	DiscountResponse,
	DiscountUserData,
} from './order.types'
import {
	Upsell,
	GetRestaurantResponse,
	RestaurantComment,
} from './restaurant.types'
import {
	API,
	ConsentType,
	RestaurantStaticInfo,
	TimeEstimationPayload,
	TimeEstimationListPayload,
	CityAutocompleteResult,
	GeoAutocompleteRestaurant,
} from './types'
import cockpit from './cockpit'
import { LogEntry } from 'utils/logger'
import axios from 'axios'
const sessionToken = uuid()

declare global {
	interface Window {
		restaurants?: Record<string, RestaurantStaticInfo>
	}
}

function createOrder(
	userId: string,
	restaurantId: string
): Promise<CreateOrderResponse> {
	return http
		.post(
			`/users/${userId}/orders/`,
			{ restaurant_id: restaurantId } // eslint-disable-line
		)
		.then((res) => res.data)
}

function createUser(): Promise<LoginResponse> {
	return http.post('/users/').then((res) => res.data)
}

function deleteSavedCreditCard(userId: string, cardId: number): Promise<null> {
	return http
		.delete(`/users/${userId}/saved_credit_card/${cardId}/`)
		.then((res) => res.data)
}

function deleteUserAddress(userId: string, addressId: string): Promise<void> {
	return http.delete(`/users/${userId}/addresses/${addressId}/`).then(() => {
		return
	})
}

function getActiveCities(): Promise<API.GetActiveCitiesResponse> {
	return v3Instance.get('/restaurants/cities').then((res) => res.data)
}

function getActiveRestaurants(): Promise<API.GetActiveRestaurantsResponse> {
	return v3Instance.get('/restaurants/active').then((res) => res.data)
}

function getAddressFromCitySlug(
	citySlug: string
): Promise<API.GetAddressFromCitySlugResponse> {
	return http.get(`/deeplink/?city_slug=${citySlug}`).then((res) => res.data)
}

function getAllCities(): Promise<API.GetAllCitiesResponse> {
	return http.get('/geo/?city=ACTIVE&limit=0').then((res) => res.data)
}

function getAutocompleteCity(searchValue: string) {
	return v3InstanceCached
		.get<API.GetCityAutocompleteResponse[]>(`/search/zipcode/?q=${searchValue}`)
		.then((res) =>
			res.data.map(
				(city) =>
					({
						...city,
						type: 'city',
						name: `${city.zip_code} - ${city.city_name}`,
					} as CityAutocompleteResult)
			)
		)
}

function getAutocompleteRestaurant(
	address: string
): Promise<GeoAutocompleteRestaurant[]> {
	return v3InstanceCached
		.get<API.GetGeoAutocompleteRestaurant>(
			`/search/restaurants/?query=${address}&session_token=${sessionToken}`
		)
		.then((res) => res.data.map((r) => ({ ...r, type: 'restaurant' })))
}

function getCategories(): Promise<API.GetCategoriesResponse> {
	return v3Instance.get('/restaurants/categories').then((res) => res.data)
}

function getCourierLocation(
	courierId: number
): Promise<API.GetCourierLocationResponse> {
	return apiGateway
		.get(`/hurrier/api/tracking/v2/courier-location/${courierId}`)
		.then((res) => res.data)
}

function getFrontpageImages(): Promise<API.GetFrontpageImages> {
	return http.get('/frontpage_images/').then((res) => res.data)
}

function getGdprData(userId: string): Promise<API.GdprDataResponse> {
	return http.get(`/users/${userId}/data/`).then((res) => res.data)
}

function getGeoAutocomplete(
	address: string
): Promise<API.GeoAutocompleteResponse> {
	return http
		.get(`/geo/autocomplete_v2/?query=${address}&session_token=${sessionToken}`)
		.then((res) => res.data)
}

function getGiveawaySettings(): Promise<API.GiveawaySettingsResponse> {
	return http.get('/give_away_section/mobile/').then((res) => res.data)
}

function getIsHungryClosed(): Promise<API.GetIsHungryCloseResponse> {
	return http.get('/site_settings/site_closed/').then((res) => res.data)
}

function getNewestRestaurants(
	limit: number
): Promise<API.GetNewestRestaurantsResponse> {
	return http
		.get(
			`/restaurants/?all_restaurants=true&limit=${limit}&fields=general,address&sort_by=newest`
		)
		.then((res) => res.data)
}

function getSwimlaneFavourites(
	latitude: string,
	longitude: string
): Promise<API.GetSwimlaneFavouritesResponse> {
	return http
		.get(
			`/restaurants/?lat=${latitude}&lon=${longitude}&swimlane_type=familiar&fields=general,rating,availability,address,min_order_value,delivery_fees&limit=10`
		)
		.then((res) => res.data)
}

function getOrder(userId: string, orderId: string): Promise<OrderResponse> {
	return http.get(`/users/${userId}/orders/${orderId}/`).then((res) => res.data)
}

function getOrderDiscount(
	code: string,
	data: Partial<DiscountUserData>
): Promise<DiscountResponse> {
	return http
		.get(
			`/coupons/${code}/?email=${data.email}&phone=${data.phone}&street_name=${data.streetName}&street_number=${data.streetNumber}&door=${data.door}&zipcode=${data.zipcode}&city=${data.city}&latitude=${data.latitude}&longitude=${data.longitude}&`
		)
		.then((res) => res.data)
}

function getOrderHistory(
	userId: string,
	page: number
): Promise<API.GetOrderHistoryResponse> {
	return http
		.get(
			`/users/${userId}/orders/?fields=general,number,restaurant_summary,order_price_details,delivery_address,sections,central_items,deleted_items,reorderable,participants&status=success&page=${page}`
		)
		.then((res) => res.data)
}

function getOrderTracking(
	orderNumber: string
): Promise<API.GetOrderTrackingResponse> {
	return apiGateway
		.get(`/hurrier/api/tracking/v2/orders/${orderNumber}`)
		.then((res) => res.data)
}

function getPrivacyPolicy(): Promise<{ html: string }> {
	return http.get('/privacy_policy/').then((res) => res.data)
}

function getRating(uniqueId: string): Promise<API.GetRatingResponse> {
	return http.get(`/rating/${uniqueId}/`).then((res) => res.data)
}

function getRedirects(): Promise<API.GetRedirectsResponse> {
	return v3InstanceCached.get('/pages/redirects/').then((res) => res.data)
}

function getRelatedRestaurants(
	restaurantId: string
): Promise<API.GetRelatedRestaurantsResponse> {
	return http
		.get(`/restaurants/${restaurantId}/related/`)
		.then((res) => res.data)
}

function getRestaurant(
	id: string | number,
	lat?: number,
	lon?: number
): Promise<GetRestaurantResponse> {
	if (lat && lon) {
		return http
			.get(`/restaurants/${id}/?lat=${lat}&lon=${lon}`)
			.then((res) => res.data)
	}
	return http.get(`/restaurants/${id}/`).then((res) => res.data)
}

function getRestaurantDynamicInfo(
	ids: string[],
	lat: string | number,
	lon: string | number
): Promise<API.GetRestaurantDynamicInfo> {
	return v3Instance
		.get(`/restaurants/${ids.join(',')}/${lat}/${lon}`)
		.then((res) => res.data)
}

function getRestaurantFromSlug(
	citySlug: string,
	restaurantSlug: string,
	lat: string | number,
	lon: string | number
): Promise<API.GetRestaurantFromSlugResponse> {
	return http
		.get(
			`/deeplink/?city_slug=${citySlug}&restaurant_slug=${restaurantSlug}&lat=${lat}&lon=${lon}`
		)
		.then((res) => res.data)
}

function trackForm(formId: string, data: Record<string, any>) {
	return axios
		.post<void>(
			`https://track.customer.io/api/v1/forms/${formId}/submit`,
			{ data },
			{
				headers: {
					Authorization: `Basic ${process.env.NEXT_PUBLIC_CUSTOMER_IO_TRACKING_AUTHORIZATION_KEY}`,
				},
			}
		)
		.then((res) => res.data)
}

function getRestaurantListTimeEstimation(
	payload: TimeEstimationListPayload
): Promise<API.GetTimeEstimationList> {
	return apiGateway
		.post(`/dk/tes/v1/estimations/`, payload)
		.then((res) => res.data)
}

function getRestaurantRatings(id: string): Promise<RestaurantComment[]> {
	return http.get(`/restaurants/${id}/comments/`).then((res) => res.data.data)
}

function getRestaurantStaticInfo(
	id: string | number
): Promise<API.GetRestaurantStaticInfo> {
	if (typeof window !== 'undefined' && window?.restaurants?.[id]) {
		return Promise.resolve(window.restaurants[id])
	}

	return v3InstanceCached.get(`/restaurants/${id}/info/`).then((res) => {
		if (typeof window !== 'undefined') {
			if (!window.restaurants) window.restaurants = {}
			window.restaurants[res.data.id] = res.data
		}
		return res.data
	})
}

function getRestaurantTimeEstimation(
	restaurantId: string,
	payload: TimeEstimationPayload
): Promise<API.GetTimeEstimation> {
	return apiGateway
		.post(`/dk/tes/v2/estimations/${restaurantId}`, payload)
		.then((res) => res.data)
}

function getSortedRestaurantsByCitySlug(
	citySlug: string
): Promise<API.GetSortedRestaurantList> {
	return v3InstanceCached
		.get(`/restaurants/sorted/forcity/${citySlug}/`)
		.then((res) => res.data)
}

function getSortedRestaurantsByLatLon(
	latitude: string,
	longitude: string
): Promise<API.GetSortedRestaurantList> {
	latitude = latitude.slice(0, 6)
	longitude = longitude.slice(0, 6)
	return v3InstanceCached
		.get(`/restaurants/sorted/${latitude}/${longitude}/`)
		.then((res) => res.data)
}

function getUpsell(restaurantId: string): Promise<Upsell> {
	return http
		.get(`/restaurants/${restaurantId}/upsell/`)
		.then((res) => res.data)
}

function getUser(userId: string): Promise<LoginResponse> {
	return http.get(`/users/${userId}/`).then((res) => res.data)
}

/** Gets a list of addresses for an user */
function getUserAddress(
	userId: string
): Promise<API.GetUserAddressListResponse> {
	// TODO: This endpoint returns paginated content, figure out if we use it
	return http
		.get(`/users/${userId}/addresses/?fields=address`)
		.then((res) => res.data)
}

function getUserCommentsForOrders(
	userId: string
): Promise<API.GetUserOrderComments> {
	return http.get(`/users/${userId}/comments/`).then((res) => res.data)
}

function login(
	email: string,
	password: string,
	smsCode = ''
): Promise<LoginResponse> {
	const str = email.split('@')
	const encoded = encodeURI(str[0])
	email = encoded + '@' + str[1]

	if (!smsCode) {
		const params = {
			email,
			pwd: password,
		}
		return http.post('/authorization/', params).then((res) => res.data)
	} else {
		const params = {
			email: email,
			pwd: password,
			temp_login_token: smsCode,
		}
		return http.post('/authorization/', params).then((res) => res.data)
	}
}

function loginWithFacebook(
	userId: string,
	accessToken: string
): Promise<LoginResponse> {
	const params = {
		userId,
		accessToken,
		// always send create: '1'. BE makes the verification if user exists
		create: '1',
	}
	return http.post('/authorization/', params).then((res) => res.data)
}

function logout(email: string): Promise<LogoutResponse> {
	return http
		.put('/authorization/?op=logout', { email })
		.then((res) => res.data)
}

function newsLetterSignup(
	email: string,
	zipcode?: string
): Promise<API.NewsletterSignup> {
	return http
		.post('/users/newsletter/', { email, zipcode })
		.then((res) => res.data)
}

function requestPasswordReset(email: string): Promise<unknown> {
	return http
		.put('/authorization/', {
			email,
			op: 'reset',
		})
		.then((res) => res.data)
}

function reverseGeoCode(
	lat: string | number,
	lon: string | number
): Promise<API.ReverseGeoCodeResponse> {
	return http
		.get(`/geo/reverse/?lat=${lat}&lon=${lon}`)
		.then((res) => res.data.data)
}

function searchForRestaurants(
	lat: string,
	lon: string,
	deliveryzipcode = ''
): Promise<API.GetRestaurantSearchResponse> {
	// If delivery zipcode is undefined, restaurants will never have the promoted tag
	const params = new URLSearchParams({ lat, lon })
	if (deliveryzipcode) params.append('deliveryzipcode', deliveryzipcode)
	return http
		.get(
			`/restaurants/?${params.toString()}&fields=general,address,availability,rating,min_order_value,weekly_opening_hours,delivery_fees,smiley,categories`
		)
		.then((res) => res.data)
}

function searchForRestaurantsV3(
	lat: string,
	lon: string
): Promise<API.RestaurantSearchResponseV3> {
	return v3Instance
		.get(`/restaurants/?lat=${lat}&lon=${lon}`)
		.then((res) => res.data)
}

function searchForRestaurantsForCity(
	lat: string,
	lon: string,
	zipcode: string,
	city: string
): Promise<API.GetRestaurantSearchResponse> {
	const params = new URLSearchParams({ lat, lon, zipcode, city })

	return http
		.get(
			`/restaurants/?${params.toString()}&fields=general,address,availability,rating,min_order_value,weekly_opening_hours,delivery_fees,smiley,categories`
		)
		.then((res) => res.data)
}

function submitLogs(sessionId: string, logs: LogEntry[]) {
	// We don't use the apiGateway as we don't want to run the interceptors for this api
	return axios
		.post(
			`${process.env.NEXT_PUBLIC_API_GATEWAY_URL}/log`,
			{
				loggroup: process.env.NEXT_PUBLIC_LOG_GROUP_NAME,
				stream: `${process.env.NEXT_PUBLIC_WEBSITE_VERSION}/${sessionId}`,
				logs,
			},
			{
				headers: {
					'X-API-Key': process.env.NEXT_PUBLIC_LOGGER_API_KEY,
				},
			}
		)
		.then((res) => res.data)
}

function submitNps({
	userId,
	orderNumber,
	score,
}: {
	userId: string
	orderNumber: string
	score: number
}): Promise<API.SubmitNpsResponse> {
	return http
		.post(`/users/${userId}/nps/`, {
			customer_id: Number(userId),
			order_number: orderNumber,
			score,
		})
		.then((res) => res.data)
}

function submitRating(
	uniqueId: string,
	data: API.GetRatingResponse
): Promise<API.SubmitRatingResponse> {
	return http.put(`/rating/${uniqueId}/`, data).then((res) => res.data)
}

function updateConsent(
	userId: string,
	consentType: ConsentType,
	consentGiven: boolean
): Promise<unknown> {
	return http
		.put(`/users/${userId}/consent/`, {
			consent: consentGiven,
			consent_for: consentType,
			customer_id: userId,
		})
		.then((res) => res.data)
}

function updateNps(data: {
	created_at: string
	customer_id: number
	id: number
	order_number: string
	score: number
}): Promise<API.UpdateNpsResponse> {
	return http
		.put(`/users/${data.customer_id}/nps/${data.id}/`, data)
		.then((res) => res.data)
}

function updateOrder(
	userId: string,
	order: IOrder
): Promise<UpdateOrderResponse> {
	return http
		.put(`/users/${userId}/orders/${order.id}/`, order)
		.then((res) => res.data)
}

function updateSavedCreditCard(
	userId: string,
	cardId: number,
	customName: string,
	email: string
): Promise<null> {
	return http
		.put(`/users/${userId}/saved_credit_card/${cardId}/`, {
			id: cardId,
			newname: customName,
			user: {
				email,
			},
		})
		.then(() => null)
}

function updateUser(
	userId: string,
	data: Partial<UpdateUserData>
): Promise<LoginResponse> {
	return http.put(`/users/${userId}/`, data).then((res) => res.data)
}

function updateUserAddress(
	userId: string,
	address: UserAddressListItem
): Promise<UpdateUserAddressListItemResponse> {
	return http
		.put(`/users/${userId}/addresses/${address.id}/`, address.address)
		.then((res) => res.data)
}

function getRestaurantOpeningHours(
	restaurantId: string
): Promise<API.GetRestaurantOpeningHours> {
	return v3Instance
		.get(`/restaurants/${restaurantId}/closed/`)
		.then((res) => res.data)
}

function getUserSession(
	userId: string
): Promise<{session_id: string}> {
	return http
		.get(`/users/${userId}/sx/`)
		.then((res) => res.data)
}

const api = {
	createOrder,
	createUser,
	deleteSavedCreditCard,
	deleteUserAddress,
	getActiveCities,
	getActiveRestaurants,
	getAddressFromCitySlug,
	getAllCities,
	getAutocompleteCity,
	getAutocompleteRestaurant,
	getCategories,
	getCourierLocation,
	getFrontpageImages,
	getGdprData,
	getGeoAutocomplete,
	getGiveawaySettings,
	getIsHungryClosed,
	getNewestRestaurants,
	getOrder,
	getOrderDiscount,
	getOrderHistory,
	getOrderTracking,
	getPrivacyPolicy,
	getRating,
	getRedirects,
	getRelatedRestaurants,
	getRestaurant,
	getRestaurantDynamicInfo,
	getRestaurantFromSlug,
	trackForm,
	getRestaurantListTimeEstimation,
	getRestaurantOpeningHours,
	getRestaurantRatings,
	getRestaurantStaticInfo,
	getRestaurantTimeEstimation,
	getSortedRestaurantsByCitySlug,
	getSortedRestaurantsByLatLon,
	getSwimlaneFavourites,
	getUpsell,
	getUser,
	getUserAddress,
	getUserCommentsForOrders,
	getUserSession,
	login,
	loginWithFacebook,
	logout,
	newsLetterSignup,
	requestPasswordReset,
	reverseGeoCode,
	searchForRestaurants,
	searchForRestaurantsForCity,
	searchForRestaurantsV3,
	submitLogs,
	submitNps,
	submitRating,
	updateConsent,
	updateNps,
	updateOrder,
	updateSavedCreditCard,
	updateUser,
	updateUserAddress,
	...cockpit,
}

export default api
