import { AxiosRequestConfig } from 'axios'
import { setupHttpClient } from './client'

const instance = setupHttpClient(process.env.NEXT_PUBLIC_API_BASE_URL, {
	headers: {
		common: {
			authentication: `LH api-key=${process.env.NEXT_PUBLIC_API_KEY}`,
		},
	},
})

const v3Instance = setupHttpClient(process.env.NEXT_PUBLIC_API_V3_URL)

const v3InstanceCached = setupHttpClient(
	process.env.NEXT_PUBLIC_API_V3_URL_CACHED
)

const apiGateway = setupHttpClient(
	process.env.NEXT_PUBLIC_API_GATEWAY_URL,
	undefined,
	{
		addRequestId: false,
	}
)

export function setAuthToken(token: string) {
	instance.defaults.headers.common[
		'authentication'
	] = `LH api-key=${process.env.NEXT_PUBLIC_API_KEY},token=${token}`
}

function get(path: string) {
	return instance.get(path)
}

function post(
	path: string,
	params: Record<string, unknown> = {},
	config?: AxiosRequestConfig
) {
	return instance.post(path, params, config)
}

function put(path: string, params: Record<string, unknown> = {}) {
	return instance.put(path, params)
}

function remove(path: string, params: Record<string, unknown> = {}) {
	return instance.delete(path, params)
}

export { v3Instance, v3InstanceCached, apiGateway }
export default { get, post, put, delete: remove }
