import { v5 as uuidv5 } from 'uuid'

class Ecommerce {
	/** Returns an uuid based on the order number */
	getTransactionId(orderNumber: string) {
		// Using uuidv5 will make sure we generate the same uuid given an order number
		// Ideally this will be supplied on the order to make the transactionId is the same as the one tracked in our server side tracking.
		const NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341'
		return uuidv5(orderNumber, NAMESPACE)
	}
}

export default Ecommerce
