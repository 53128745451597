import dayjs from 'dayjs'

/** Cookie names that we use */
type CookieName =
	| 'active_address'
	| 'user_id'
	| 'user_email'
	| 'user_auth_token'
	| 'session_exchange_id'
	| '_ga' // Google analytics cookie - contains the users clientId

/** Helper class for working with cookies */
export class Cookies {
	/**
	 * Get cookie value by it's name.
	 */
	static getCookieValue = (name: CookieName) => {
		const b = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)')
		if (!b) return ''
		const cookie = b.pop()
		return cookie ? decodeURIComponent(cookie) : ''
	}

	// TODO: Would be sweet to be able to infer the type of the value based on the key
	/**
	 * Sets a cookie with a default expiration of 30 days
	 * @param name - Name of the cookie
	 * @param value - String value of the cookie
	 * @param daysToExpire - Amount of days in which the cookie will expire
	 */
	static setCookie = (name: CookieName, value: string, daysToExpire = 30) => {
		if (!daysToExpire) {
			document.cookie = `${name}=${encodeURIComponent(value)};path=/`
			return
		}

		const Date = dayjs().add(daysToExpire, 'days').toDate()
		document.cookie = `${name}=${encodeURIComponent(
			value
		)};path=/; Expires=${Date}`
	}

	/**
	 * Delete a cookie by it's name
	 */
	static deleteCookie = (name: string) => {
		// Delete the cookie by setting expiration to a previous date
		document.cookie = `${name}=; Expires=${new Date(0)}`
	}
}
