import * as React from 'react'

const Heart = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="26"
			viewBox="0 0 30 26"
			fill="#babbc3"
			{...props}
		>
			<path
				d="M316.707,415.177a8.283,8.283,0,0,0-11.511-.2l-1.078,1.074-.874-.881a8.29,8.29,0,0,0-5.862-2.43h0a8.234,8.234,0,0,0-5.854,14.037l11.363,11.467a1.71,1.71,0,0,0,1.212.5,1.784,1.784,0,0,0,1.236-.5l11.371-11.457A8.19,8.19,0,0,0,316.707,415.177Z"
				transform="translate(-289.116 -412.737)"
			/>
		</svg>
	)
}

export default Heart
