import { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'
import logger from '../../utils/logger'
import { v4 as uuidv4 } from 'uuid'

interface RequestConfig extends AxiosRequestConfig {
	metadata?: {
		startTime: number
	}
}

interface Response extends AxiosResponse<any> {
	config: RequestConfig
}

const getResponseTime = (config: RequestConfig) => {
	if (!config?.metadata?.startTime) return
	return Date.now() - config.metadata.startTime
}

const requestIdHeaderKey = 'X-Request-Id'

export type InterceptorRequestOptions = {
	addRequestId: boolean
}

const onRequestSuccess = (options: InterceptorRequestOptions) => (
	config: AxiosRequestConfig
): RequestConfig => {
	const data: Record<string, unknown> = {
		baseURL: config.baseURL,
		url: config.url,
		method: config.method,
		location: typeof window !== 'undefined' ? window.location.href : undefined,
	}

	if (options.addRequestId) {
		data.request_id = `web-${uuidv4()}`
		config.headers[requestIdHeaderKey] = data.request_id
	}

	// Ideally we add meta data when performing the request. This would require us to ignore quite a few of axios types, as it does not provide a way to do so.
	if (
		config.method === 'put' &&
		config.url?.startsWith('/users/') &&
		config.url.includes('/orders/')
	) {
		data.requestData = {
			operation: config.data?.operation,
		}
	}

	// Log all other requests than the ones made to our logging endpoint
	if (config.url !== '/log') {
		logger.info(`API Request - ${config.baseURL}${config.url}`, data)
	}

	return {
		...config,
		metadata: {
			startTime: Date.now(),
		},
	}
}

const onRequestError = (error: any) => {
	const attributes: any = {
		message: error?.message,
		responseTime: getResponseTime(error.config),
		location: typeof window !== 'undefined' ? window.location.href : undefined,
	}

	if ('isAxiosError' in error) {
		const [, token] =
			error.config?.headers?.authentication?.split('token=') || []
		attributes.code = error.code
		attributes.url = error.config.url
		attributes.statusCode = error.response?.status
		attributes.isRequestSentWithToken = token !== undefined
		attributes.isTokenNullOrEmpty = !token
		attributes.request_id = error.config.headers?.[requestIdHeaderKey]
	}

	logger.error(`API Request ERROR`, attributes)
	return Promise.reject(error)
}

const onResponseSuccess = (response: Response) => {
	logger.info('API Response', {
		baseURL: response.config.baseURL,
		url: response.config.url,
		status: response.status,
		responseTime: getResponseTime(response.config),
		location: typeof window !== 'undefined' ? window.location.href : undefined,
		request_id: response.config.headers?.[requestIdHeaderKey],
	})

	return response
}

const onResponseError = (error: any) => {
	const attributes: any = {
		message: error?.message,
		responseTime: getResponseTime(error.config),
		location: typeof window !== 'undefined' ? window.location.href : undefined,
	}

	if ('isAxiosError' in error) {
		const [, token] =
			error.config?.headers?.authentication?.split('token=') || []
		attributes.code = error.code
		attributes.url = error.config.url
		attributes.statusCode = error.response?.status
		attributes.isRequestSentWithToken = token !== undefined
		attributes.isTokenNullOrEmpty = !token
		attributes.request_id = error.config.headers?.[requestIdHeaderKey]
	}

	logger.error('API Response ERROR', attributes)

	return Promise.reject(error)
}

export const useInterceptors = (
	instance: AxiosInstance,
	config: InterceptorRequestOptions
) => {
	instance.interceptors.request.use(onRequestSuccess(config), onRequestError)
	instance.interceptors.response.use(onResponseSuccess, onResponseError)
}
