import * as React from 'react'

const Profile = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="28"
			viewBox="0 0 30 28"
			fill="#3a9b2e"
			{...props}
		>
			<g transform="translate(-309.039 -441.403)">
				<circle
					cx="7.115"
					cy="7.115"
					r="7.115"
					transform="translate(316.353 441.403)"
				/>
				<path
					d="M334.026,459.174a16.554,16.554,0,0,0-9.866-3.271c-.076,0-.152,0-.231,0a16.558,16.558,0,0,0-9.871,3.269,12.649,12.649,0,0,0-5.011,8.138.538.538,0,0,0,.125.443.569.569,0,0,0,.427.192h28.88a.567.567,0,0,0,.427-.192.536.536,0,0,0,.125-.442A12.637,12.637,0,0,0,334.026,459.174Z"
					transform="translate(0 1.458)"
				/>
			</g>
		</svg>
	)
}

export default Profile
