import * as React from 'react'

const House = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="#babbc3"
			{...props}
		>
			<path
				d="M-923.957,967.4h-1.32v7.56a.823.823,0,0,1-.83.812h-3.449a.822.822,0,0,1-.83-.812v-4.374a.333.333,0,0,0-.341-.333h-2.789a.333.333,0,0,0-.341.333v4.374a.823.823,0,0,1-.83.812h-3.449a.822.822,0,0,1-.83-.812V967.4h-1.32a.819.819,0,0,1-.6-1.4l8.175-8a.875.875,0,0,1,1.192,0l8.175,8A.835.835,0,0,1-923.957,967.4Z"
				transform="translate(941.12 -957.775)"
			/>
		</svg>
	)
}

export default House
