import * as React from 'react'
import styled from 'styled-components'
import { breakpointValues } from 'styles/breakpoints'

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

const StyledButton = styled.button<{ disabled: boolean }>`
	background: ${(props) => props.theme.colors.orange};
	color: ${(props) => props.theme.colors.white};
	font-weight: bold;
	padding: 12px 20px;
	border-radius: ${(props) => props.theme.buttonBorderRadius};
	overflow: hidden;
	border: none;
	cursor: pointer;

	${(props) =>
		props.disabled &&
		`
		background: ${props.theme.colors.gray};
		color: ${props.theme.colors.grayDark};
		pointer-events: none;
	`};

	@media (max-width: ${breakpointValues.tablet}px) {
		min-height: 52px;
	}
`

const DefaultButton = ({ children, disabled, ...rest }: Props) => {
	return (
		<StyledButton disabled={!!disabled} {...rest}>
			{children}
		</StyledButton>
	)
}

export default DefaultButton
