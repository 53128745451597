import * as React from 'react'
import styled from 'styled-components'

type Props = {
	children: React.ReactNode
}

const Wrapper = styled.div`
	background: ${(props) => props.theme.colors.white};
	height: 100%;
	overflow: auto;
`

// We should probably take more control over this animation. Right now it has just been copied from Sweetalert.
// Upgrading Sweetalert might break this, as the classNames might change
const Success = (props: Props) => {
	return (
		<Wrapper className="sucecss">
			<div
				className="swal2-icon swal2-success swal2-icon-show"
				style={{ display: 'flex' }}
			>
				<div
					className="swal2-success-circular-line-left"
					style={{ backgroundColor: 'rgb(255, 255, 255)' }}
				></div>
				<span className="swal2-success-line-tip"></span>{' '}
				<span className="swal2-success-line-long"></span>
				<div className="swal2-success-ring"></div>{' '}
				<div
					className="swal2-success-fix"
					style={{ backgroundColor: 'rgb(255, 255, 255)' }}
				></div>
				<div
					className="swal2-success-circular-line-right"
					style={{ backgroundColor: 'rgb(255, 255, 255)' }}
				></div>
			</div>
			{props.children}
		</Wrapper>
	)
}

export default Success
