import { PaymentOption } from 'components/Cart/PaymentOptions/PaymentOption'
import Ecommerce from './ecommerce'
import { SessionStorage } from 'utils/session-storage'
import logger from 'utils/logger'
import { Cookies } from 'utils/cookies'
import { breakpointValues } from 'styles/breakpoints'
import DataLayer, { DataLayerEvent } from 'utils/analytics/dataLayer'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || ''
export type PageViewPrependableString = '/restaurant' | '/list'

declare global {
	interface Window {
		ga?: {
			getAll: () => any
		}
	}
}

const dataLayerEvent = ({
	action,
	category,
	label,
	nonInteraction = false,
}: DataLayerEvent) => {
	DataLayer.defaultPush({
		event: 'reactEvent',
		category,
		action,
		label,
		nonInteraction,
	})
}

class Analytics {
	isAuthenticated: boolean
	ecommerce: Ecommerce

	constructor() {
		this.isAuthenticated = false
		this.ecommerce = new Ecommerce()
	}

	/** Track page views */
	trackPageview(page: string, prependString?: PageViewPrependableString) {
		// Google analytics will automatically set the document location including query params
		// Therefore we remove any query params from the pageview we track
		;[page] = page.split('?')
		if (prependString) {
			page = prependString + page
		}

		DataLayer.push('', {
			event: 'virtualPageview',
			page_path: page,
			custom_map: {
				dimension1: process.env.NEXT_PUBLIC_WEBSITE_VERSION,
			},
		})
	}

	/** Track when user submits an order with no errors */
	trackSuccessfulOrder() {
		this.trackPageview('/checkoutpayment/')
	}

	private getClientIdFromCookie() {
		try {
			const gaCookie = Cookies.getCookieValue('_ga')
			if (!gaCookie) return
			return gaCookie.split('.').slice(2).join('.')
		} catch (e) {
			logger.error('Failed fallback to retrieve google analytics client id', {
				errorMessage: e.message,
			})
		}
	}

	private getClientIdFromTracker() {
		try {
			if (!window.ga) return 'ga-not-initialized'
			const trackers = window.ga.getAll()
			let i, len
			for (i = 0, len = trackers.length; i < len; i += 1) {
				if (
					trackers[i].get('trackingId') ===
					process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID
				) {
					return trackers[i].get('clientId')
				}
			}
		} catch (e) {
			logger.error('Failed to retrieve google analytics client id', {
				errorMessage: e.message,
			})
		}
		return 'missing-ga-clientId'
	}

	getClientId() {
		let clientId = this.getClientIdFromTracker()
		if (['ga-not-initialized', 'missing-ga-clientId'].includes(clientId)) {
			const fallbackClientId = this.getClientIdFromCookie()
			if (fallbackClientId) {
				logger.info('retrieved google analytics client id by fallback method', {
					reason: clientId,
				})
				clientId = fallbackClientId
			}
		}
		return clientId
	}

	/** Keep track of how many times user has returned */
	updateReturnCount() {
		let returnCount = Number(localStorage.getItem('return_count') || '0')
		if (!returnCount) {
			localStorage.setItem('return_count', '1')
			SessionStorage.setItem('return_session_tracking', 'true')
			return
		}

		// We don't actually care what the value is, just whether it's set or not
		const sessionTracking = SessionStorage.getItem('return_session_tracking')
		if (!sessionTracking) {
			returnCount += 1
			localStorage.setItem('return_count', returnCount.toString())
			SessionStorage.setItem('return_session_tracking', 'true')
		}
	}

	/** Emit new website event */
	sendNewWebsiteEvent() {
		dataLayerEvent({
			category: 'website',
			action: 'New website',
			nonInteraction: true,
		})
	}

	/** Track what payment method user selected, if user selected one, when clicking continue */
	sendCheckoutSelectedPaymentOption(selectedPaymentOption?: PaymentOption) {
		if (selectedPaymentOption) {
			const { name, card_type } = selectedPaymentOption.data.paymentMethod
			dataLayerEvent({
				category: 'checkout',
				action: 'payment',
				label: `${name} ${card_type ? card_type : ''}`.trim(),
			})
		}
	}

	/** Track when order has errors after trying to finalize order */
	sendOrderValidityError(action: string) {
		dataLayerEvent({
			category: 'Checkout_error',
			action,
			nonInteraction: true,
		})
	}

	/** Track when location arrow in search field is clicked */
	sendSearchGetLocationClick() {
		dataLayerEvent({
			category: 'Search',
			action: 'Find me',
			label: 'Clicked',
		})
	}

	/** Track when we successfully find an address based on coordinates from window.geolocation api */
	sendSearchGetLocationSuccess() {
		dataLayerEvent({
			category: 'Search',
			action: 'Find me',
			label: 'Address found',
		})
	}

	/** Track when we can't find an address based on coordinates from window.geolocation api */
	sendSearchGetLocationError() {
		dataLayerEvent({
			category: 'Search',
			action: 'Find me',
			label: 'Address not found',
		})
	}

	/** Track restaurant on restaurant page enter */
	sendRestaurantId(restaurantId: string) {
		DataLayer.defaultPush({
			event: 'restaurant_id',
			restaurant_id: restaurantId,
		})
	}

	/** Track search value on restaurant search */
	sendRestaurantMenuSearch(value: string) {
		dataLayerEvent({
			category: 'Restaurant',
			action: 'Menu search',
			label: value,
		})
	}

	/** Track when restaurant menu item image is clicked */
	SendRestaurantMenuItemImageClick(
		restaurantId: string,
		menuSectionName: string,
		itemName: string
	) {
		const device =
			typeof window !== 'undefined' &&
			window.innerWidth >= breakpointValues.tablet
				? 'desktop'
				: 'mobile'

		dataLayerEvent({
			category: 'Restaurant',
			action: `Menu item image click ${device}`,
			label: [restaurantId, menuSectionName, itemName].join(':'),
		})
	}

	/** Track whether the user has been exposed to upsell items or not */
	sendUpsellItemsDisplayed(displayed: boolean) {
		dataLayerEvent({
			category: 'checkout',
			action: 'upselling',
			label: displayed ? 'displayed' : 'not_displayed',
		})
	}

	/** Track when user adds an upsell item to cart */
	sendUpsellItemAdded() {
		dataLayerEvent({
			category: 'checkout',
			action: 'upselling',
			label: 'added',
		})
	}

	// min 3 characters after 1 sec delay
	// searchvalue; zipcode
	/** Track filter being selected on restaurant list */
	sendSearchResultSearchRestaurant(label: string) {
		dataLayerEvent({
			category: 'Search Result',
			action: 'Restaurant search',
			label,
		})
	}

	//
	/** Track filter being selected on restaurant list */
	sendSearchResultFoodTypeSelect(label: string) {
		dataLayerEvent({
			category: 'Search Result',
			action: 'Food type filter',
			label,
		})
	}

	/** Track when user ends up on order confirmation page and isn't authorized */
	sendOrderConfirmationNotFound() {
		dataLayerEvent({
			category: 'Order Confirmation',
			action: 'Error',
			label: 'Not found',
		})
	}

	sendOrderConfirmationNotAuthenticated() {
		dataLayerEvent({
			category: 'Order Confirmation',
			action: 'Error',
			label: 'Not authenticated',
		})
	}

	/** Track filter being selected on restaurant list */
	sendPreviousCommentsButtonDisplayed(displayed: boolean) {
		dataLayerEvent({
			category: 'checkout',
			action: 'previous comment',
			label: displayed ? 'button_displayed' : 'button_not_displayed',
		})
	}

	/** Track filter being selected on restaurant list */
	sendPreviousCommentAdded(added: boolean) {
		dataLayerEvent({
			category: 'checkout',
			action: 'previous comment',
			label: added ? 'added' : 'closed',
		})
	}

	/** Track filter being selected on restaurant list */
	sendSearchResultSortClick(label: string) {
		dataLayerEvent({ category: 'Search Result', action: 'Sort', label })
	}

	/** Track filter being selected on restaurant list */
	sendSearchResultFilterClick(label: string) {
		dataLayerEvent({ category: 'Search Result', action: 'Filter', label })
	}

	/** Track the addresses being searched for that has no results */
	sendGeoAutocompleteSearchNotFound(searchValue: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'Location not found',
			label: searchValue,
		})
	}

	/** Track when users search an address in the navigation bar and gets multiple results */
	sendGeoSearchShowSuggestionsModalNavbar(searchValue?: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_display_navbar',
			label: searchValue ? searchValue : 'undisclosed',
		})
	}

	/** Track when users search an address in the navigation bar and gets multiple results */
	sendGeoSearchSuggestionModalSelectNavbar() {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_select_navbar',
			label: 'undisclosed',
		})
	}

	/** Track when user has no active address and clicks to see delivery fee */
	sendGeoSearchShowSuggestionsModalRestaurant(searchValue?: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_display_restaurant',
			label: searchValue ? searchValue : 'undisclosed',
		})
	}

	/** Track when user clicks on a suggestion in the search suggestion modal */
	sendGeoSearchSuggestionModalSelectRestaurant() {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_select_restaurant',
			label: 'undisclosed',
		})
	}

	/** Track when user has no active address and clicks to see delivery fee */
	sendGeoSearchShowSuggestionsModalRestaurantInfo(searchValue?: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_display_restaurant_info',
			label: searchValue ? searchValue : 'undisclosed',
		})
	}

	/** Track when user clicks on a suggestion in the search suggestion modal */
	sendGeoSearchSuggestionModalSelectRestaurantInfo() {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_select_restaurant_info',
			label: 'undisclosed',
		})
	}

	/** Track when user clicks to go to checkout without an active address */
	sendGeoSearchShowSuggestionsModalSubmitCart(searchValue?: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_display_submit_cart',
			label: searchValue ? searchValue : 'undisclosed',
		})
	}

	/** Track when user selects an address after going to checkout with no active address */
	sendGeoSearchShowSuggestionsModalSelectSubmitCart() {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_select_submit_cart',
			label: 'undisclosed',
		})
	}

	/** Track when user searches for an address that doesn't match any results from autocomplete api */
	sendGeoSearchShowSuggestionsModalFrontpage(searchValue?: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_display_frontpage',
			label: searchValue ? searchValue : 'undisclosed',
		})
	}

	/** Track when user clicks on a suggestion in the search suggestion modal */
	sendGeoSearchSuggestionModalSelectFrontpage() {
		dataLayerEvent({
			category: 'Search',
			action: 'search_event_popup_suggestions_select_frontpage',
			label: 'undisclosed',
		})
	}

	/** Track when user visits a restaurant that's closed */
	sendRestaurantClosed(restaurantName: string, zipcode: string) {
		dataLayerEvent({
			category: 'Restaurant',
			action: 'isclosed',
			label: [restaurantName, zipcode].join(';'), // USE RESTAURANT; zipcode
			nonInteraction: true,
		})
	}

	/** Track when user applies coupon */
	sendCheckoutFormCouponValidate(coupon: string, isValid: boolean) {
		dataLayerEvent({
			category: 'CheckoutForm:CouponValidate',
			action: coupon,
			label: isValid ? 'TRUE' : 'FALSE',
		})
	}

	/** Track when user manages to get on checkout page for an order that is already submitted */
	sendOrderAlreadySubmitted() {
		dataLayerEvent({
			category: 'CheckoutForm',
			action: 'OrderAlreadySubmitted',
		})
	}

	/** Track what payment method user used when finalizing order */
	sendCheckoutPaymentMethodSelected(paymentOption: PaymentOption) {
		const category = 'checkout'
		const action = 'payment'
		const { paymentMethod } = paymentOption.data

		// bips or cash
		if (paymentMethod.id === '11' || paymentMethod.id === '0') {
			dataLayerEvent({ category, action, label: paymentMethod.name })
		}
		// epay
		if (paymentMethod.id === '12') {
			let label = `${paymentMethod.name} ${paymentMethod.card_type}`

			if (paymentOption.data.paymentMethod.saved_card_to_use?.id) {
				label = `saved ${label}`
			}
			dataLayerEvent({
				category,
				action,
				label,
			})
		}
	}

	/** Track when order can't be submitted bacause restaurant is not available */
	sendCheckRestaurantAvailability(reason: string, orderId: string) {
		dataLayerEvent({
			category: 'Checkout',
			action: 'Order not submitted',
			label: `Restaurant is not available for order. Order id: ${orderId}. Reason: ${reason}`,
		})
	}

	/** Track when user performs a search for a restaurant */
	sendSearchAddress(zipcode: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'Address search',
			label: zipcode,
		})
	}

	/** Track when user performs a search for a restaurant */
	sendSearchRestaurant(city: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'Restaurant search',
			label: city,
		})
	}

	/** Track when user performs a search for a city */
	sendSearchCity(zipcode: string) {
		dataLayerEvent({
			category: 'Search',
			action: 'City search',
			label: zipcode,
		})
	}

	/** Track when user goes to a restaurant that doesn't deliver to his address */
	sendRestaurantNoDeliverTo(
		restaurantName: string,
		zipcode: string,
		distance: number
	) {
		dataLayerEvent({
			category: 'error',
			action: 'search_error_no_delivery_to',
			label: [restaurantName, zipcode, distance].join(';'),
		})
	}

	/** Track when user is exposed to the GMB modal popup */
	sendGMBModalView(restaurantName: string) {
		dataLayerEvent({
			category: 'Campaign',
			action: 'RestaurantPopup',
			label: restaurantName,
			nonInteraction: true,
		})
	}

	sendTransactionIsDeliveryOrPickup(isTakeout: boolean) {
		dataLayerEvent({
			category: 'Customer',
			action: 'Transaction',
			label: isTakeout ? 'Pickup' : 'Delivery',
		})
	}

	sendTransactionIsReturningCustomer(isReturningCustomer: boolean) {
		dataLayerEvent({
			category: 'Customer',
			action: 'Transaction',
			label: isReturningCustomer ? 'New customer' : 'Returning customer',
		})
	}

	sendTransactionNewsletterSubscriptionStatus(subscribed: boolean) {
		dataLayerEvent({
			category: 'Customer',
			action: 'Transaction',
			label: subscribed ? 'Subscribe' : 'Not Subscribe',
		})
	}

	sendTransactionIsGroupOrder(isGroupOrder: boolean) {
		dataLayerEvent({
			category: 'Customer',
			action: 'Transaction',
			label: isGroupOrder ? 'GroupOrder' : 'SingleOrder',
		})
	}

	sendCustomerNPSScore(score: number) {
		dataLayerEvent({
			category: 'Customer',
			action: 'NPS',
			label: score.toString(),
		})
	}
}

// Copy static methods onto our instance of analytics
export default new Analytics()
