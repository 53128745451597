import { LoginResponse } from "api/user.types";

export const LoginWithCredentialsResponse: LoginResponse = {
  "token": "",
  "user": {
    "addresses": "",
    "general": {
      "tags": [],
      "lastname": "",
      "given_consent": true,
      "phone": "",
      "no_passwords": false,
      "newsletter": false,
      "name": "",
      "created_at": "",
      "birthdate": "",
      "last_login": "",
      "facebook_id": "",
      "bitcoin_address": "",
      "email": ""
    },
    "last_order_address": {
      "city_slug": "",
      "city": "",
      "user_id": "",
      "etage": "",
      "street_number": "",
      "tags": [],
      "country": "",
      "company": "",
      "zipcode": "",
      "comments": "",
      "phone": "",
      "street_name": "",
      "longitude": 0,
      "lastname": "",
      "latitude": 0,
      "door": "",
      "building_name": "",
      "id": "",
      "name": ""
    },
    "favorites": "",
    "cards_on_file": [],
    "id": "",
    "customer_transaction_count": 0,
    "uri": "",
    "comments": "",
    "orders": "",
    "stamps": {}
  }
}