import { createGlobalStyle } from 'styled-components'
import { breakpoints } from './breakpoints'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: Proxima Nova Soft,proximanovasoft,helvetica neue,Helvetica,Roboto,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    background: #f9f9f9;
  }

  input, button, textarea, select{
    font-family: inherit;
  }

  a {
    color: #e89042;
    text-decoration: none;
  }

  hr{
    width: 100%;
    height: 1px;
    background-color: #eee;
    border: none;
  }

  /* These styled are used in the Typography component */
  h1,h2,h3,h4,h5,h6,p{
    font-weight: unset;
    margin: 0;
  }

  button:disabled{
    cursor: not-allowed;
  }

  /* End of Typography styles */

  /* Modal styles */
  .modal-popup{
    background: transparent !important;
  }
  .swal2-content{
    /* padding: 0 12px !important; TODO: update on migration*/
    padding: 0 !important;
  }
  .swal2-container{
    z-index: ${(props) => props.theme.zIndex.MODAL} !important;
  }
  /* Backdrop class used for sweetalert modal */
  .modal-backdrop{
    background: rgba(0,0,0,0.7);
  }

  body.modal-open{
    overflow: hidden; // Prevents background scrolling when modal is shown
  }

  body.swal2-shown > #__next, body.modal-open > #__next{
    position: sticky; // Without position sticky backdrop-filter doesn't work in chrome
    overflow: hidden; // Prevents background scrolling when modal is shown
  }

  .modal-backdrop-v2{
    position: fixed;
    backdrop-filter: blur(2px) brightness(.8);
  }

  /* Backdrop class when modal should be infront of freshchat */
  .modal-backdrop-unclosable{
    z-index: ${(props) => props.theme.zIndex.MODAL} !important;
  }
  /* Backdrop for react-modal */
  .ReactModal__Overlay{
    background: rgba(0,0,0,0.7) !important;
  }

  .modal-header{
    background: #FFF;
  }
  /* Modal styles end */

  /*
  .strike-beside-word{
    line-height: 1.6;
    font-weight: 400;
    font-family: Proxima Nova Soft,proximanovasoft;
    font-weight: bold;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.3rem;
    display: inline-block;
    color: #d4d4d4;
    position: relative;
    overflow: hidden;
  }

  .strike-beside-word:before{
    margin-left: -50%;
    position: absolute;
    top: 51%;
    width: 46%;
    height: .1rem;
    content: '\a0';
    background: #eee;
  }

  .strike-beside-word:after{
    margin-left: 4%;
    position: absolute;
    top: 51%;
    width: 46%;
    height: .1rem;
    content: '\a0';
    background: #f9f9f9;
  } */

  /* This is for displaying the success icon in our sweetalert modal correctly */
  .swal2-icon.swal2-success.swal2-icon-show, .swal2-icon.swal2-error.swal2-icon-show{
    font-size: 14px;
  }
  .swal2-popup{
    padding: 0 !important;
  }

  .swal2-popup{
    width: unset !important;
  }

  @media (max-width: 767px) {
    .swal2-popup{
      width: 95vw !important;
    }

    .modal-top-alignment{
      margin-top: 80px !important;
    }
    .large-screen-only{
      display: none !important;
    }
  }

  @media (min-width: 768px) {
    .small-screen-only{
      display: none !important;
    }
  }

  .custom_fc_frame {
    display: none;
  }

  /* Add this class to show freschchat on some mobile pages */
  .show_fc {
    display: block;
  }

  @media ${breakpoints.tablet} {
    .custom_fc_frame{
      display: block;
    }
  }
`

export default GlobalStyle
