import * as React from 'react'
import styled from 'styled-components'
import * as Icon from 'components/Icon'
import { breakpoints, breakpointValues } from 'styles/breakpoints'

type Props = {
	onClose?: () => void
	closeIconColor?: string
	children: React.ReactNode
	maxWidth?: number
	minWidth?: number
	/** Setting this to true will allow fullscreen modal on smaller devices than tablets */
	enableSmallDeviceFullscreen?: boolean
	hasOverflowingContent?: boolean
}

export const fullScreenModalBreakpointValue = breakpointValues.mobileL

const Container = styled.div<{
	minWidth: number
	maxWidth: number
	supportFullscreen?: boolean
	hasOverflowingContent?: boolean
}>`
	margin: auto;
	position: relative;
	max-width: ${(props) => props.maxWidth}px;
	display: inline-flex;
	width: 100%;
	border-radius: 5px;
	max-height: 100vh;
	${(props) =>
		!props.hasOverflowingContent &&
		`
		overflow: hidden;
	`}

	@media ${breakpoints.tablet} {
		min-width: ${(props) => props.minWidth}px;
	}

	${(props) =>
		props.supportFullscreen &&
		`
		@media (max-width: ${fullScreenModalBreakpointValue}px) {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: initial;
			border-radius: 0;
		}
	`}
`

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	max-height: inherit;
`

export const CloseButton = styled(Icon.Close)<{ closeIconColor?: string }>`
	color: ${(props) =>
		props.closeIconColor
			? props.closeIconColor
			: props.theme.colors.grayAlmostDark};
	position: absolute;
	top: 8px;
	right: 8px;
	padding: 4px;
	cursor: pointer;
	z-index: ${(props) => props.theme.zIndex.MODAL_CLOSE_BUTTON};
	&:hover {
		color: ${(props) => props.theme.colors.grayDark};
	}
`

/** This is the base layout that should be applied to all modals */
const ModalBaseLayout = ({
	minWidth = 500,
	maxWidth = 500,
	...props
}: Props) => {
	return (
		<Container
			minWidth={minWidth}
			maxWidth={maxWidth}
			supportFullscreen={props.enableSmallDeviceFullscreen}
			hasOverflowingContent={props.hasOverflowingContent}
		>
			{props.onClose && (
				<CloseButton
					onClick={props.onClose}
					closeIconColor={props.closeIconColor}
				/>
			)}
			<Wrapper>{props.children}</Wrapper>
		</Container>
	)
}

export default ModalBaseLayout
