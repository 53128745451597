import * as React from 'react'

export function SvgScooter(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 38 27"
			width="1em"
			height="1em"
			fill="#444444"
			{...props}
		>
			<path d="M16.5 0h-12v1.5h12z" />
			<path
				d="M3 10l-3 8v4h3.5a5 5 0 0010 0H27l.51-.31V22a5 5 0 107.18-4.5h1.81v-1l-3-2h-3l-4-7.5H28l1-1V4l-1.5-1h-2l-.5-.5h-4.5V4h3v1.5L25 9l1 4v5.5l-1 .5h-8l-1-1v-6l1-.5v-2H3zm27.19 10.09l1.71-1a2.91 2.91 0 01.6-.06 3 3 0 11-2.31 1.09zM11.5 22a3 3 0 01-6 0z"
				fillRule="evenodd"
			/>
			<path d="M.5 4h12v1.5H.5z" />
		</svg>
	)
}
