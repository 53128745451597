import * as React from 'react'
import styled from 'styled-components'

type Props = {
	children: string
} & React.HTMLAttributes<HTMLElement>

const Strike = styled.i`
	line-height: 1.6;
	font-weight: 400;
	font-family: Proxima Nova Soft, proximanovasoft;
	font-weight: bold;
	text-align: center;
	width: 100%;
	box-sizing: border-box;
	font-size: 12px;
	display: inline-block;
	color: ${(props) => props.theme.colors.gray};
	position: relative;
	overflow: hidden;

	&:before {
		margin-left: -50%;
		position: absolute;
		top: 51%;
		width: 46%;
		height: 0.1rem;
		content: '\\a0';
		background: ${(props) => props.theme.colors.grayMediumLight};
	}

	&:after {
		margin-left: 4%;
		position: absolute;
		top: 51%;
		width: 46%;
		height: 0.1rem;
		content: '\\a0';
		background: ${(props) => props.theme.colors.grayMediumLight};
	}
`

const StrikeBesideWord = ({ children, ...props }: Props) => {
	return <Strike {...props}>{children}</Strike>
}

export default StrikeBesideWord
