import * as React from 'react'
import FacebookLogin from 'components/Authentication/Login/Facebook'
import { LoginResponse, FacebookLoginResponse } from 'api/user.types'
import { useTranslation } from 'react-i18next'
import Typography from 'components/Typography'
import styled from 'styled-components'
import StrikeBesideWord from './StrikeBesideWord'
import DefaultLogin from './Default'
import logger from 'utils/logger'
import Modal from 'components/Modal'

export const Wrapper = styled.div`
	background: ${(props) => props.theme.colors.grayLight};
	padding: 15px;
	text-align: left;
`

export const Label = styled(Typography)`
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 4px;
	&:not(:first-child) {
		margin-top: 16px;
	}
`

const ForgotPassword = styled(Typography)`
	color: ${(props) => props.theme.colors.orange};
	font-weight: bold;
	text-align: center;
	margin-top: 12px;
	cursor: pointer;
`

const Info = styled(Typography)`
	margin-top: 10px;
	text-align: center;
`

export const StyledStrikeBesideWord = styled(StrikeBesideWord)`
	margin: 12px 0;
`

type Props = {
	login: (email: string, password: string) => Promise<LoginResponse>
	resetPassword: () => void
	facebookLogin: (response: FacebookLoginResponse) => Promise<LoginResponse>
}

const LoginModalView = (props: Props) => {
	const { t } = useTranslation()

	const facebookCallback = async (response: FacebookLoginResponse) => {
		return props
			.facebookLogin(response)
			.then(() => {
				Modal.close()
			})
			.catch((err) => logger.newrelicError(err))
	}

	const defaultLogin = (email: string, password: string) => {
		return props.login(email, password).then(() => {
			Modal.close()
		})
	}

	return (
		<Wrapper className="container" data-testid="login-modal">
			<FacebookLogin callback={facebookCallback}>
				{t('continue_with_facebook')}
			</FacebookLogin>
			<StyledStrikeBesideWord>{t('or')}</StyledStrikeBesideWord>

			<DefaultLogin onLogin={defaultLogin}>{t('login_button')}</DefaultLogin>

			<ForgotPassword
				onClick={props.resetPassword}
				variant="body2"
				component="p"
			>
				{t('forgotten_password')}
			</ForgotPassword>
			<Info variant="body2" component="p" className="login-modal-info">
				{t('automatic_account_creation')}
			</Info>
		</Wrapper>
	)
}

export default LoginModalView
