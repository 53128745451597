import * as React from 'react'
import styled from 'styled-components'
import { breakpointValues } from 'styles/breakpoints'

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	fakeDisabled?: boolean
}

const StyledButton = styled.button<{
	disabled: boolean
	$fakeDisabled?: boolean
}>`
	${(props) => {
		const disabledStyles = `
			background: ${props.theme.InteractionTertiery} !important;
			color: ${props.theme.BackgroundLight} !important;
			border: none !important;
			outline: none !important;
			cursor: not-allowed !important;
		`

		return `
			min-width: 150px;
			border-radius: 5px;
			height: 44px;
			cursor: pointer;
			border: none;
			outline: none;
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: pre;
			overflow: hidden;
			font-size: ${props.theme.ButtonFontSize};
			font-weight: ${props.theme.ButtonFontWeight};
			font-family: ${props.theme.ButtonFontFamily};
			// line-height: ${props.theme.ButtonLineHeight};
			line-height: 20px;
			color: ${props.theme.ButtonColor};

			&:disabled {
				${disabledStyles}
			}

			${props.$fakeDisabled && disabledStyles}
		`
	}}

	@media (max-width: ${breakpointValues.mobileS}px) {
		min-width: 100px;
	}
`

const ButtonBaseLayout = ({
	children,
	disabled,
	fakeDisabled,
	...props
}: Props) => {
	return (
		<StyledButton disabled={!!disabled} $fakeDisabled={fakeDisabled} {...props}>
			{children}
		</StyledButton>
	)
}

export default ButtonBaseLayout
