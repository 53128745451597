import * as React from 'react'
import { ThemeProvider } from 'styled-components'
import { DefaultTheme } from 'styles/theme'
import GlobalStyles from 'styles/globals'
import { TypographyStyles as LegacyTypographyStyles } from 'components/Typography/styles'
import { TypographyStyles } from 'components/Shared/Typography/styles'

type Props = {
	children: React.ReactNode
}

/** We expose this module for our modal to use and still have access to theme props through context */
const CustomThemeProvider = (props: Props) => {
	return <ThemeProvider theme={DefaultTheme}>{props.children}</ThemeProvider>
}

const HungryThemeProvider = (props: Props) => {
	return (
		<CustomThemeProvider>
			<GlobalStyles />
			<LegacyTypographyStyles />
			<TypographyStyles />
			{props.children}
		</CustomThemeProvider>
	)
}

export { CustomThemeProvider }
export default HungryThemeProvider
