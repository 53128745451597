/**
 * Do not edit directly
 * Generated on Mon, 03 Oct 2022 19:27:44 GMT
 */

export const H1FontFamily = "Proxima Nova Soft";
export const H1StyleName = "Bold";
export const H1FontSize = "26px";
export const H1FontWeight = "700";
export const H1LineHeight = "0px";
export const H1Color = "rgba(29,32,56,1)";
export const H2FontFamily = "Proxima Nova Soft";
export const H2StyleName = "Bold";
export const H2FontSize = "22px";
export const H2FontWeight = "700";
export const H2LineHeight = "0px";
export const H2Color = "rgba(29,32,56,1)";
export const H3FontFamily = "Proxima Nova Soft";
export const H3StyleName = "Bold";
export const H3FontSize = "16px";
export const H3FontWeight = "700";
export const H3LineHeight = "0px";
export const H3Color = "rgba(29,32,56,1)";
export const H4FontFamily = "Proxima Nova Soft";
export const H4StyleName = "Regular";
export const H4FontSize = "16px";
export const H4FontWeight = "400";
export const H4LineHeight = "0px";
export const H4Color = "rgba(29,32,56,1)";
export const BackgroundLight = "rgba(255,255,255,1)";
export const Discount = "rgba(186,187,195,1)";
export const HighlightPrimary = "rgba(58,155,46,1)";
export const HighlightSecondary = "rgba(255,134,0,1)";
export const Icons = "rgba(141,143,155,1)";
export const InteractionBasket = "rgba(61,63,84,1)";
export const InteractionPrimary = "rgba(147,204,22,1)";
export const InteractionSecondary = "rgba(29,32,56,1)";
export const InteractionTertiery = "rgba(221,221,225,1)";
export const Offer = "rgba(230,247,104,1)";
export const ContentFontFamily = "Proxima Nova Soft";
export const ContentStyleName = "Regular";
export const ContentFontSize = "14px";
export const ContentFontWeight = "400";
export const ContentLineHeight = "0px";
export const ContentColor = "rgba(29,32,56,1)";
export const HelpertextFontFamily = "Proxima Nova Soft";
export const HelpertextStyleName = "Regular";
export const HelpertextFontSize = "12px";
export const HelpertextFontWeight = "400";
export const HelpertextLineHeight = "0px";
export const HelpertextColor = "rgba(29,32,56,1)";
export const HoverPrimary = "rgba(134,188,16,1)";
export const ButtonFontFamily = "Proxima Nova Soft";
export const ButtonStyleName = "Bold";
export const ButtonFontSize = "14px";
export const ButtonFontWeight = "700";
export const ButtonLineHeight = "0px";
export const ButtonColor = "rgba(29,32,56,1)";
export const BorderRadiusButton = "5px";