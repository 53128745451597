import * as React from 'react'
import styled from 'styled-components'
import Typography from 'components/Typography'
import { useTranslation } from 'react-i18next'

export type Props = {
	children: React.ReactNode
	titleKey?: string
	enableSmallDeviceFullscreen?: boolean
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-height: inherit;
	height: 100%;
`

const Header = styled.div`
	background: ${(props) => props.theme.colors.green};
	width: 100%;
	padding: 12px 32px;
	min-height: 50px;
	color: ${(props) => props.theme.colors.white};
	box-sizing: border-box;
`

const Title = styled(Typography)`
	color: ${(props) => props.theme.colors.white};
	text-align: center;
`

const Content = styled.div`
	flex: 1;
`

/** This is the default layout that should be applied to all standard modals.\
 * e.g. non-error modals
 */
const DefaultLayout = (props: Props) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Header>
				{props.titleKey && (
					<Title component="h3" variant="h6">
						{t(props.titleKey)}
					</Title>
				)}
			</Header>
			<Content>{props.children}</Content>
		</Wrapper>
	)
}

export default DefaultLayout
