import * as React from 'react'

// This was generated with the @svgr/cli e.g. npx @svgr/cli --icon icon.svg
export function SvgViabillSmallCart(props: any) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 60 20"
			width="60"
			height="20"
			{...props}
		>
			<symbol
				id="viabill-small-cart_svg__a"
				viewBox="-636.2 -133.7 1272.4 267.5"
			>
				<path
					d="M-270.6 133.7c-12.5 0-27.1-9.2-32.6-20.4l-108.5-224.2c-5.4-11.2.3-20.4 12.8-20.4h13.5c12.5 0 27.1 9.2 32.6 20.4l108.5 224.2c5.4 11.2-.3 20.4-12.8 20.4h-13.5zm-210.8-265c12.5 0 27.1 9.2 32.6 20.4l108.6 224.2c5.4 11.2-.3 20.4-12.8 20.4h-13.5c-12.5 0-27.1-9.2-32.6-20.4l-78.2-161.5c-5.4-11.2-14.3-11.2-19.8 0l-78.2 161.5c-5.4 11.2-20.1 20.4-32.6 20.4h-13.5c-12.5 0-18.2-9.2-12.8-20.4l108.8-224.2c5.4-11.2 20.1-20.4 32.6-20.4h11.4zm307.8 184c5.4 11.2 14.3 11.2 19.8 0l79.2-163.6c5.4-11.2 20.1-20.4 32.6-20.4h13.5c12.5 0 18.2 9.2 12.8 20.4l-108.5 224.2c-5.4 11.2-20.1 20.4-32.6 20.4h-13.7c-12.5 0-27.1-9.2-32.6-20.4l-108.5-224.2c-5.4-11.2.3-20.4 12.8-20.4h13.5c12.5 0 27.1 9.2 32.6 20.4l79.1 163.6z"
					fill="#01b0ef"
				/>
				<path
					d="M238 114.2c0 9.4-7.6 17-17 17h-13c-9.4 0-17-7.6-17-17v-231c0-9.4 7.6-17 17-17h13c9.4 0 17 7.6 17 17v231zm199.6-247.9c9.4 0 12.4 6.4 6.8 13.9l-13.9 18.6c-5.6 7.5-17.8 13.5-27.2 13.5h-64c-9.4 0-17.3 8.1-17.3 17.5v185c0 9.4-7.4 16.5-16.7 16.5h-13c-9.4 0-17.3-7.2-17.3-16.5v-231c0-9.4 7.9-17.5 17.3-17.5h145.3zm189 0c9.4 0 12.4 6.4 6.8 13.9l-13.9 18.6c-5.6 7.5-17.8 13.5-27.2 13.5h-64c-9.4 0-17.3 8.1-17.3 17.5v185c0 9.4-7.4 16.5-16.7 16.5h-13c-9.4 0-17.3-7.2-17.3-16.5v-231c0-9.4 7.9-17.5 17.3-17.5h145.3zm-453.3 84c0 14-5.2 27.9-12.5 39.7-3.8 6.1-8.4 11.8-13.5 16.9-2.4 2.3-4.9 4.5-7.6 6.6-2.8 2.1-3.2 6.2-2 9.2 2.8 7.2 7.4 13.4 9.9 20.8 2.1 6.3 2.7 12.5 2.4 19.1-.6 11.2-3.3 22.3-9.1 31.9-10.6 17.5-29.7 27-48.9 31.8-18.9 4.7-38.3 5.4-57.7 5.4H-69.4c-3.6 0-7.6.4-11.1-.6-5.6-1.7-7.7-7.1-5.9-12.5 1-3 2.7-5.9 4.1-8.7 4.1-8.4 7.3-16.5 15.7-21.7 8.5-5.3 17.6-4.5 27.2-4.5h59.2c10 0 20 0 30-.1 13.4-.4 29.5-1.4 39.8-11.4 9.7-9.5 8.2-25.3-3.1-32.8-11.9-7.8-27.4-7.1-41-7.5-16.2-.4-32.4-.2-48.6-.2h-14.3c-7.4 0-21 1.5-20.5-9.7.2-3.6 2.2-6.9 3.7-10 1.8-3.6 3.6-7.3 5.4-10.9C-24.2-8.3-14-15.3-3.3-15.3H37c7.2 0 14.4.1 21.6-.3 9-.4 18.4-.7 27.2-2.6 15.7-3.5 32.4-13.3 33.5-31C120.5-68.4 103.7-79 87-82.6c-7.1-1.6-14.5-1.7-21.8-2.1-9.7-.5-19.3-.5-29-.5-3.7 0-7.8.4-11.2-1.1-5.5-2.4-6.4-8.4-4.1-13.5 2.7-6.1 5.9-12 8.8-17.9 2.6-5.3 6.9-9.7 12-12.6 6-3.4 12.2-3.1 18.9-2.9 7.1.2 14.2 1 21.2 1.7 5.9.6 11.7 1.5 17.5 2.8 18.9 4.2 37.5 12.2 51.7 25.6 14.5 14 22.3 33.1 22.3 53.4z"
					fill="#00aeef"
				/>
			</symbol>
			<use
				width={1272.4}
				height={267.5}
				x={-636.2}
				y={-133.7}
				transform="matrix(.04428 0 0 -.04428 28.6 10.076)"
				overflow="visible"
				xlinkHref="#viabill-small-cart_svg__a"
			/>
		</svg>
	)
}
