import * as React from 'react'
import styled from 'styled-components'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

const StyledButton = styled.button`
	background: none;
	outline: none;
	border: none;
	text-decoration: underline;
	font-size: 14px; // This should come from style dictionary
	cursor: pointer;
	padding: 0;
	color: ${(props) => props.theme.ButtonColor};
`

const SecondaryButton = (props: Props) => {
	return <StyledButton {...props} />
}

export default SecondaryButton
