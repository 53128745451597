import * as React from 'react'
import styled from 'styled-components'
import Typography from 'components/Shared/Typography'
import * as SVG from 'components/SVG'
import { ReactChild } from 'react'
import { v4 as uuid } from 'uuid'

type Props = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	label?: string
	errorLabel?: string
	children?: CustomIcon
}

type CustomIcon = {
	icon: ReactChild
}

const Wrapper = styled.div``

const FormField = styled.div`
	display: block;
	height: 44px;
`

const FormFieldControl = styled.div<{ $active: boolean }>`
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	width: 100%;
`

const FormFieldLabel = styled.label<{ $active: boolean; $hasIcon: boolean }>`
	display: block;
	left: 0;
	margin: 0;
	padding: 12px 15px 0;
	position: absolute;
	top: 0;
	transition: all 0.4s;
	width: 100%;
	font-size: ${(props) => props.theme.ContentFontSize};
	font-weight: ${(props) => props.theme.ContentFontWeight};
	line-height: 20px;
	color: ${(props) => props.theme.Icons};
	text-align: left;
	${(props) =>
		props.$active &&
		`
			font-size: 10px;
			line-height: 14px;
			transform: translateY(-6px);
		`}
	${(props) =>
		props.$hasIcon &&
		`
			padding-left: 40px;
		`}
`

const FormFieldInput = styled.input<{
	$error: boolean
	$isPassword: boolean
	$hasIcon: boolean
}>`
	box-sizing: border-box;
	appearance: none;
	background: transparent;
	border: 1px solid ${(props) => props.theme.InteractionTertiery};
	border-radius: 5px;
	font-size: ${(props) => props.theme.ContentFontSize};
	font-weight: ${(props) => props.theme.ContentFontWeight};
	line-height: 20px;
	color: ${(props) => props.theme.InteractionSecondary};
	display: block;
	outline: 0;
	padding: 16px 0 0 15px;
	width: 100%;
	height: 44px;

	&:disabled {
		pointer-events: none;
		background: #f5f5f5;
	}

	${(props) =>
		props.$error &&
		`
			border: 1px solid  ${props.theme.HighlightSecondary};
		`}
	${(props) =>
		props.$isPassword &&
		`
			padding-right: 51px;
		`}
	${(props) =>
		props.$hasIcon &&
		`
			padding-left: 40px;
		`}
`

const FormFieldError = styled(Typography)`
	color: ${(props) => props.theme.HighlightSecondary};
	margin-top: 6px;
	text-align: left;
`

const TogglePassword = styled(SVG.Eye)<{ $showPass: boolean }>`
	width: 23px;
	height: 14px;
	display: block;
	position: absolute;
	top: 15px;
	right: 15px;
	${(props) =>
		props.$showPass &&
		`
			fill: ${props.theme.HighlightPrimary};
		`}
`

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<HTMLInputElement, Props>(
	({ label, errorLabel, children, id, ...props }, ref) => {
		const [isActive, setIsActive] = React.useState(false)
		const [isPassShown, setIsPassShown] = React.useState(false)
		const hasValue = props.value
		const isPassword = props.type === 'password'
		let fieldType = props.type
		if (isPassword) {
			fieldType = isPassShown ? 'text' : 'password'
		}
		const inputId = id || `${label}-${uuid()}`

		return (
			<Wrapper>
				<FormField>
					<FormFieldControl $active={isActive}>
						{children && children.icon}
						<FormFieldLabel
							htmlFor={inputId}
							$active={isActive || !!hasValue}
							$hasIcon={!!(children && children.icon)}
						>
							{label}
						</FormFieldLabel>
						<FormFieldInput
							$isPassword={isPassword}
							$hasIcon={!!(children && children.icon)}
							ref={ref as any}
							id={inputId}
							$error={!!errorLabel}
							onFocus={() => setIsActive(true)}
							onBlur={() => setIsActive(false)}
							{...props}
							type={fieldType}
						/>
						{isPassword ? (
							<TogglePassword
								$showPass={isPassShown}
								onClick={() => setIsPassShown(!isPassShown)}
							></TogglePassword>
						) : null}
					</FormFieldControl>
				</FormField>
				{errorLabel ? (
					<FormFieldError variant="body" component="p">
						{errorLabel}
					</FormFieldError>
				) : null}
			</Wrapper>
		)
	}
)

export default Input
