import React from 'react'
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ModalBaseLayout from './Base'
import { CustomThemeProvider } from 'styles/ThemeProvider'

const MySwal = withReactContent(Swal)

type HungryModalConfiguration = {
	hideCloseButton?: boolean
} & Pick<
	React.ComponentProps<typeof ModalBaseLayout>,
	'minWidth' | 'maxWidth' | 'minHeight'
>

export type ModalOptions = SweetAlertOptions & HungryModalConfiguration

class Modal {
	private showModal = async ({
		html,
		hideCloseButton = false,
		minHeight,
		maxWidth,
		minWidth,
		...options
	}: ModalOptions): Promise<SweetAlertResult> => {
		// Hide body overflow to prevent scrolling on the background
		// document.body.style.overflow = 'hidden'
		const onClose = () => this.close(null)
		const { showClass, customClass, ...config } = options

		const res = await MySwal.fire({
			showConfirmButton: false,

			// We wrap the component in theme provider to allow access to theme styles
			// If we add the ability to change theme later on, we need to pass the active theme
			html: (
				<CustomThemeProvider>
					<ModalBaseLayout
						onClose={hideCloseButton ? undefined : onClose}
						minHeight={minHeight}
						maxWidth={maxWidth}
						minWidth={minWidth}
					>
						{html}
					</ModalBaseLayout>
				</CustomThemeProvider>
			),
			showClass: {
				backdrop: 'modal-backdrop-v2',
				...showClass,
			},
			customClass: {
				...customClass,
			},
			...config,
		})
		// Reset body overflow to reenable body scroll
		// document.body.style.overflow = 'unset'
		return res
	}

	open<T = any>({
		html,
		...options
	}: ModalOptions): Promise<SweetAlertResult<T>> {
		return this.showModal({
			html,
			...options,
		})
	}

	close(value?: any): void {
		MySwal.close({
			value,
			dismiss: Swal.DismissReason.close,
			isConfirmed: false,
			isDenied: false,
			isDismissed: true,
		})
	}

	confirm(value: any): void {
		MySwal.close({
			value,
			isConfirmed: true,
			isDenied: false,
			isDismissed: false,
		})
	}
}

export default new Modal()
