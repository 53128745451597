import * as React from 'react'
import styled from 'styled-components'

type Props = {
	progress?: 0 | 1 | 2 | 3 | 4
} & React.SVGProps<SVGSVGElement>

const Line = styled.line<{ active: boolean }>`
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 12px;
	${(props) =>
		props.active
			? `
    fill:#429236;
    stroke:#429236;
  `
			: `
    fill:#dadbda;
    stroke:#dadbda;
  `}
`

const Progress = ({ progress = 0, ...props }: Props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 464 12.22" {...props}>
			<Line active={progress > 0} x1="6" y1="6" x2="68" y2="6" />
			<Line active={progress > 1} x1="92" y1="6.22" x2="247" y2="6.22" />
			<Line active={progress > 2} x1="271" y1="6.22" x2="372" y2="6.22" />
			<Line active={progress > 3} x1="396" y1="6.22" x2="458" y2="6.22" />
		</svg>
	)
}

export default Progress
