import axios from 'axios'
import { Cockpit } from './types'
import { CollectionParams } from 'api/types'

const instance = axios.create({
	baseURL: 'https://cockpit.aws.hungrycloud.net',
	headers: {
		// TODO: IMPORTANT Make sure the auth token only has read access
		authorization: `Bearer 595379ad1873a159bdaf57f0d7534b`,
	},
})

function listCollections() {
	return instance
		.get('/api/collections/listCollections')
		.then((res) => res.data)
}

function getCollection<T extends Cockpit.CollectionType>(
	name: T,
	params?: CollectionParams
): Promise<Cockpit.CollectionResponse<T>> {
	return instance
		.post(`/api/collections/get/${name}`, params)
		.then((res) => res.data)
}

function getCollectionSchema(name: string): Promise<Cockpit.CollectionSchema> {
	return instance
		.get(`/api/collections/collection/${name}`)
		.then((res) => res.data)
}

function getPost() {
	return instance.get('/api/collections/get/posts').then((res) => res.data)
}

function getSingleton(name: string) {
	return instance.get(`/api/singletons/get/${name}`)
}

export default {
	getCollection,
	getCollectionSchema,
	getPost,
	getSingleton,
	listCollections,
}
