import React from 'react'
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ErrorModal from './error'
import ModalBaseLayout from './base'
import Success from './success'
import BaseError from './base-error'
import { CustomThemeProvider } from 'styles/ThemeProvider'
import DefaultLayout, { Props as DefaultLayoutProps } from './default-layout'

const MySwal = withReactContent(Swal)

type HungryModalConfiguration = {
	closeIconColor?: string
	hideCloseButton?: boolean
	enableSmallDeviceFullscreen?: boolean
	hasOverflowingContent?: boolean
}

export type ModalOptions = SweetAlertOptions & HungryModalConfiguration

class Modal {
	private showModal = ({
		html,
		closeIconColor,
		enableSmallDeviceFullscreen,
		hasOverflowingContent,
		hideCloseButton = false,
		...options
	}: ModalOptions): Promise<SweetAlertResult> => {
		const onClose = () => this.close(null)
		const { showClass, customClass, ...config } = options

		return MySwal.fire({
			showConfirmButton: false, // Don't show confirm button by default
			// We wrap the component in theme provider to allow access to theme styles
			// If we add the ability to change theme later on, we need to pass the active theme
			html: (
				<CustomThemeProvider>
					<ModalBaseLayout
						onClose={hideCloseButton ? undefined : onClose}
						closeIconColor={closeIconColor}
						enableSmallDeviceFullscreen={enableSmallDeviceFullscreen}
						hasOverflowingContent={hasOverflowingContent}
					>
						{html}
					</ModalBaseLayout>
				</CustomThemeProvider>
			),
			showClass: {
				popup: 'modal-popup',
				backdrop: 'modal-backdrop',
				...showClass,
			},
			customClass: {
				header: 'modal-header',
				...customClass,
			},
			...config,
		})
	}

	open<T = any>({
		html,
		titleKey,
		...options
	}: ModalOptions & Omit<DefaultLayoutProps, 'children'>): Promise<
		SweetAlertResult<T>
	> {
		return this.showModal({
			html: <DefaultLayout titleKey={titleKey}>{html}</DefaultLayout>,
			closeIconColor: '#FFF',
			...options,
		})
	}

	contentPage = ({ html, closeIconColor, ...options }: ModalOptions) => {
		const onClose = () =>
			this.close({
				dismiss: Swal.DismissReason.cancel,
				isConfirmed: false,
				isDenied: false,
				isDismissed: true,
			})

		return this.showModal({
			html: (
				<CustomThemeProvider>
					<ModalBaseLayout
						onClose={onClose}
						closeIconColor={closeIconColor}
						minWidth={700}
						maxWidth={700}
					>
						{html}
					</ModalBaseLayout>
				</CustomThemeProvider>
			),
			...options,
		})
	}

	close(value?: any): void {
		MySwal.close({
			value,
			dismiss: Swal.DismissReason.close,
			isConfirmed: false,
			isDenied: false,
			isDismissed: true,
		})
	}

	confirm(value: any): void {
		MySwal.close({
			value,
			isConfirmed: true,
			isDenied: false,
			isDismissed: false,
		})
	}

	showError(msg: string | string[], options: SweetAlertOptions = {}): void {
		this.showModal({
			html: <ErrorModal errors={typeof msg === 'string' ? [msg] : msg} />,
			...options,
		})
	}

	error({ html, ...options }: SweetAlertOptions) {
		this.showModal({
			html: <BaseError>{html}</BaseError>,
			...options,
		})
	}

	success({ html, ...options }: SweetAlertOptions) {
		this.showModal({
			html: <Success>{html}</Success>,
			...options,
		})
	}

	unclosable({
		html,
		titleKey,
		...options
	}: ModalOptions & Omit<DefaultLayoutProps, 'children'>) {
		this.open({
			html,
			titleKey,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			hideCloseButton: true,
			showClass: {
				backdrop: 'modal-backdrop modal-backdrop-unclosable',
			},
			...options,
		})
	}
}

export default new Modal()
