import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Shared/Modals/modal'
import ButtonPrimary from 'components/Shared/Buttons/Primary'
import ButtonSecondary from 'components/Shared/Buttons/Secondary'
import Typography from 'components/Shared/Typography'
import Input from 'components/Shared/Form/Input'
import { breakpoints } from 'styles/breakpoints'
import * as SVG from 'components/SVG'
import logger from 'utils/logger'
import { LoginResponse, FacebookLoginResponse } from 'api/user.types'
import FacebookLogin from 'components/Authentication/Login/Facebook'
import LoadingIndicator from 'components/LoadingIndicator'

type Props = {
	login: (email: string, password: string) => Promise<LoginResponse>
	resetPassword: () => void
	facebookLogin: (response: FacebookLoginResponse) => Promise<LoginResponse>
}

const Wrapper = styled.div`
	padding: 52px 18px;
	@media ${breakpoints.tablet} {
		padding: 44px 60px 40px;
	}
`

const IconProfile = styled(SVG.Profile)`
	fill: ${(props) => props.theme.HighlightPrimary};
	margin: 0 auto;
	width: 30px;
	height: 28px;
	display: block;
`

const Heading = styled(Typography)`
	margin: 18px 0 8px;
`

const Text = styled(Typography)`
	margin: 0 auto 15px;
`

const FormHeading = styled(Typography)`
	margin: 30px auto 15px;
`

const FormWrapper = styled.div`
	display: flex;
	flex-flow: column;
	width: 260px;
	margin: 0 auto;
	& > div {
		margin-bottom: 15px;
	}
	@media ${breakpoints.mobileM} {
		width: 300px;
	}
	@media ${breakpoints.tablet} {
		width: 400px;
	}
`

const FormFieldError = styled(Typography)`
	color: ${(props) => props.theme.HighlightSecondary};
	text-align: left;
`

const ForgotPassword = styled(ButtonSecondary)`
	align-self: flex-end;
`

const LoginButton = styled(ButtonPrimary)`
	width: 200px;
	margin: 30px auto 20px;
`

const LoadingIndicatorContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`

const Divider = styled.div`
	border: 1px solid #e8e8ea;
	width: 100%;
`

const FacebookLoginButton = styled(FacebookLogin)`
	width: 200px;
	height: 44px;
	margin: 15px auto 0;
`

const LoginModal = ({ login, resetPassword, facebookLogin }: Props) => {
	const { t } = useTranslation()
	const [credentials, setCredentials] = React.useState({
		email: '',
		password: '',
	})
	const [loading, setLoading] = React.useState(false)
	const [error, setError] = React.useState('')

	const facebookCallback = async (response: FacebookLoginResponse) => {
		return facebookLogin(response)
			.then(() => {
				Modal.close()
			})
			.catch((err) => logger.error(err))
	}

	const onSubmit = async () => {
		setError('')
		setLoading(true)
		try {
			await login(credentials.email, credentials.password)
		} catch (err: any) {
			setError('login_modal_error')
			if (err.response.status !== 618) {
				logger.error(err)
			}
			return
		} finally {
			setLoading(false)
		}
		Modal.close()
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSubmit()
		}
	}

	const onInput = (e: React.FormEvent<HTMLInputElement>) => {
		setCredentials({
			...credentials,
			[e.currentTarget.name]: e.currentTarget.value,
		})
	}

	return (
		<Wrapper>
			<IconProfile />
			<Heading component="h2" variant="h2">
				{t('who_are_you')}
			</Heading>
			<Text component="p" variant="body">
				Lad os ikke være så ukendte. Fortæl os hvem du er, så du kan bestille.
			</Text>
			<FormHeading component="h3" variant="h3">
				{t('existing_customer')}
			</FormHeading>
			<FormWrapper>
				<Input
					onChange={onInput}
					name="email"
					label={t('email')}
					type="email"
					value={credentials.email}
					onKeyPress={handleKeyDown}
				/>
				<Input
					onChange={onInput}
					name="password"
					label={t('password')}
					type="password"
					value={credentials.password}
					onKeyPress={handleKeyDown}
				/>
				{error ? (
					<FormFieldError variant="body" component="p">
						{t(error)}
					</FormFieldError>
				) : null}
				<ForgotPassword onClick={resetPassword}>
					{t('forgotten_password')}
				</ForgotPassword>
				{loading ? (
					<LoadingIndicatorContainer>
						<LoadingIndicator />
					</LoadingIndicatorContainer>
				) : (
					<LoginButton
						onClick={onSubmit}
						disabled={!credentials.email || !credentials.password}
					>
						{t('login_button')}
					</LoginButton>
				)}
				<Divider />
				<FacebookLoginButton callback={facebookCallback}>
					{t('continue_with_facebook')}
				</FacebookLoginButton>
			</FormWrapper>
		</Wrapper>
	)
}

export default LoginModal
