import * as React from 'react'

const Cutlery = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="29.999"
			viewBox="0 0 24 29.999"
			fill="#3a9b2e"
			{...props}
		>
			<g transform="translate(9185.374 19965.686)">
				<path
					d="M257.455,3a1.475,1.475,0,0,1,1.46,1.494v5.989a3,3,0,0,0,1.46,2.581V4.5a1.466,1.466,0,1,1,2.932,0v8.57a3.261,3.261,0,0,0,1.46-2.752V4.5a1.463,1.463,0,1,1,2.926,0v5.723a6.21,6.21,0,0,1-4.386,6.042V31.443a1.461,1.461,0,1,1-2.92,0V16.253A5.947,5.947,0,0,1,256,10.489V4.5A1.468,1.468,0,0,1,257.455,3Z"
					transform="translate(-9441.374 -19968.629)"
				/>
				<path
					d="M58.792,2.023a.294.294,0,0,1,.254.3V30.517a1.461,1.461,0,1,1-2.92,0V20.034H53.494a.3.3,0,0,1-.294-.307C53.246,2.513,57.673,1.9,58.792,2.023Z"
					transform="translate(-9220.421 -19967.697)"
				/>
			</g>
		</svg>
	)
}

export default Cutlery
