import * as React from 'react'

const Watch = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="#babbc3"
			{...props}
		>
			<g transform="translate(-257 -2267)">
				<circle cx="8.5" cy="8.5" r="8.5" transform="translate(257 2267)" />
				<path
					fill="#fff"
					d="M211.373,276.807a.748.748,0,0,1-.53-.22l-3.138-3.139v-5.679a.75.75,0,0,1,1.5,0v5.058l2.7,2.7a.751.751,0,0,1-.53,1.281Z"
					transform="translate(57.045 2003.432)"
				/>
			</g>
		</svg>
	)
}

export default Watch
