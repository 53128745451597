import { createGlobalStyle } from 'styled-components'

export const TypographyStyles = createGlobalStyle`
	.h1,.h2,.h3,.h4,.h5,.h6,.body1,.body2{
		font-family: Proxima Nova Soft,proximanovasoft;
		color: #444;
	}
	.h1,.h2,.h3,.h4,.h5,.h6{
		font-weight: bold;
	}
	.h1{
		font-size: 96px;
	}
	.h2{
		font-size: 60px;
		line-height: 1.167;
	}
	.h3{
		font-size: 48px;
		line-height: 1.2;
	}
	.h4{
		font-size: 34px;
		line-height: 1.167;
	}
	.h5{
		font-size: 24px;
		line-height: 1.334;
	}
	.h6{
		font-size: 20px;
		line-height: 1.3;
	}
	.body1{
		font-size: 16px;
		line-height: 1.5;
		color: #828585;
	}
	.body2{
		font-size: 14px;
		line-height: 1.43;
		color: #828585;
	}
	.subtitle1{
		font-size: 16px;
		line-height: 1.5;
		color: #444;
		font-weight: bold;
	}
	.subtitle2{
		font-size: 14px;
		line-height: 1.43;
		color: #444;
		font-weight: bold;
	}
`
