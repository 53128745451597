import * as React from 'react'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from 'components/LoadingIndicator'
import Input from 'components/Forms/Input'
import styled from 'styled-components'
import { DefaultButton } from 'components/Buttons'
import Typography from 'components/Typography'
import { Label } from './login-modal-view'
import logger from 'utils/logger'

type Props = {
	children: string
	email?: string
	onLogin: (email: string, password: string) => Promise<void>
}

const LoginButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`

const LoginButton = styled(DefaultButton)`
	width: 100%;
`

const ErrorMessage = styled(Typography)`
	color: red;
	padding-top: 12px;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
`

const DefaultLogin = ({ email = '', ...props }: Props) => {
	const { t } = useTranslation()
	const [credentials, setCredentials] = React.useState({ email, password: '' })
	const [loading, setLoading] = React.useState(false)
	const [error, setError] = React.useState('')

	const onSubmit = () => {
		if (!credentials.email || !credentials.password) {
			setError('login_modal_no_input_error')
			return
		}
		setLoading(true)
		props
			.onLogin(credentials.email, credentials.password)
			.then(() => setError(''))
			.catch((err) => {
				setError('login_modal_error')
				// 618 is invalid credentials
				if (err.response.status !== 618) {
					logger.newrelicError(err)
				}
			})
			.finally(() => setLoading(false))
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSubmit()
		}
	}

	const onInput = (e: React.FormEvent<HTMLInputElement>) => {
		setCredentials({
			...credentials,
			[e.currentTarget.name]: e.currentTarget.value,
		})
	}

	return (
		<div>
			<Content>
				<Label variant="body2" component="label">
					{t('email')}
				</Label>
				<Input
					value={credentials.email}
					data-testid="login-input-email"
					onChange={onInput}
					name="email"
					autoComplete="email"
					placeholder={t('email')}
					type="email"
					onKeyPress={handleKeyDown}
				/>
				<Label variant="body2" component="label">
					{t('password')}
				</Label>
				<Input
					value={credentials.password}
					data-testid="login-input-password"
					onChange={onInput}
					name="password"
					autoComplete="current-password"
					placeholder={t('password')}
					type="password"
					onKeyPress={handleKeyDown}
				/>
			</Content>
			{error ? (
				<ErrorMessage
					component="p"
					variant="body1"
					data-testid="login-error-text"
				>
					{t(error)}
				</ErrorMessage>
			) : null}
			<LoginButtonWrapper>
				{loading ? (
					<LoadingIndicator />
				) : (
					<LoginButton onClick={onSubmit} data-testid="login-button">
						{props.children}
					</LoginButton>
				)}
			</LoginButtonWrapper>
		</div>
	)
}

export default DefaultLogin
