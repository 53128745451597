import dayjs from 'dayjs'

type SessionName =
	| 'return_session_tracking'
	| 'coid' // Not sure when this is set, but old code said it's from marketing campaigns
	| 'mc_cid' // Not sure when this is set, old code said it's for GA campaigns
	| 'hungry_session_id'

/** Helper class for working with Session Storage */
export class SessionStorage {
	/**
	 * Sets an item in session storage.
	 * If hoursToExpire is set, any attemps to retrieve the key after the hours has passed, will make it act as the key was never set.
	 * @param key - Name of the session key
	 * @param value - Value of the session key
	 * @param hoursToExpire - Amount of hours that the item is valid for
	 */
	static setItem(key: SessionName, value: string, hoursToExpire?: number) {
		if (typeof hoursToExpire !== 'number') {
			sessionStorage.setItem(key, value)
			return
		}

		const expiresAt = dayjs().add(hoursToExpire, 'hours').toDate()
		sessionStorage.setItem(key, JSON.stringify({ expiresAt, value }))
	}

	/**
	 * Retrieves an item from the session storage.\
	 * In case the key had an expiration time, the key will not be returned if it has passed its expiration time.
	 * @param key - Name of the session key
	 */
	static getItem(key: SessionName) {
		const item = sessionStorage.getItem(key)
		if (!item) return
		try {
			const { expiresAt, value } = JSON.parse(item)
			if (dayjs().isBefore(expiresAt)) return value

			SessionStorage.deleteItem(key)
			return
		} catch (e) {
			return item
		}
	}

	static deleteItem(key: SessionName) {
		sessionStorage.removeItem(key)
	}
}
