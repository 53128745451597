import * as React from 'react'
import * as SVG from 'components/SVG'
import Typography from 'components/Typography'
import LoadingIndicator from 'components/LoadingIndicator'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import useDebounce from 'utils/useDebounce'

export type InputDebounceFunction = (
	value: string | number | readonly string[] | undefined
) => void

type Props = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	label?: string
	errorLabel?: string
	labelRight?: string
	onLabelRightClick?: () => void
	loading?: boolean
	onDeleteIconClick?: () => void
	debounceFunction?: InputDebounceFunction
	showSearchIcon?: boolean
}

const Wrapper = styled.span`
	display: flex;
	flex-direction: column;
	position: relative;
`

const FormRow = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
`

const Label = styled(Typography)`
	font-weight: bold;
	margin-top: 16px;
	font-size: 12px;
	margin-bottom: 4px;
`

const LabelRight = styled(Label)`
	color: ${(props) => props.theme.colors.orange};
	text-decoration: underline;
`

const InputWrapperField = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
`

const InputLoader = styled.span`
	position: absolute;
	right: 12px;
	height: 100%;
	display: flex;
	align-items: center;
	.form-input {
		padding-right: 48px;
	}
`

const SearchIconWrapper = styled.span`
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const InputErrorMessage = styled(Typography)`
	color: ${(props) => props.theme.colors.redDark};
	margin-top: 2px;

	input:focus + & {
		display: none;
	}
`

const DeleteInput = styled(animated.div)<{ $showDeleteButton: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 12px;
	width: 32px;
	height: 100%;
	cursor: pointer;

	${(props) =>
		!props.$showDeleteButton &&
		`
		pointer-events: none;
		cursor: auto;
	`}
`

const DeleteInputIcon = styled(SVG.DeleteTextIcon)`
	width: 18px;
	fill: ${(props) => props.theme.colors.grayMediumDark};
	&:hover {
		cursor: pointer;
		fill: ${(props) => props.theme.colors.grayAlmostDark};
	}
`

const FormInput = styled.input<{ $error: boolean; $showSearchIcon: boolean }>`
	padding: ${(props) =>
		props.$showSearchIcon ? '11px 11px 11px 42px' : '11px'};
	border: 1px solid ${(props) => props.theme.colors.grayMediumLight};
	border-radius: ${(props) => props.theme.BorderRadiusButton};
	color: ${(props) => props.theme.colors.grayDark};
	flex: 1;
	&:disabled {
		background: ${(props) => props.theme.colors.grayLight};
	}

	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
	}

	${(props) =>
		props.$error &&
		`
		outline-color: ${props.theme.colors.redLight};
		border: 1px solid ${props.theme.colors.redLight};
		caret-color: ${props.theme.colors.redLight};
		&:focus{
			background: ${props.theme.colors.pinkBright};
		}
	`}
`

const Input = React.forwardRef<HTMLInputElement, Props>(
	(
		{
			label,
			errorLabel,
			className,
			loading,
			onDeleteIconClick,
			labelRight,
			showSearchIcon,
			onLabelRightClick,
			...props
		},
		ref
	) => {
		const showDeleteButton = !!onDeleteIconClick && !!props.value
		const deleteIconSpring = useSpring({
			opacity: showDeleteButton ? 1 : 0,
			bottom: showDeleteButton ? 0 : -6,
		})
		const debouncedValue = useDebounce(props.value, 1000)

		React.useEffect(() => {
			if (props.debounceFunction) {
				props.debounceFunction(debouncedValue)
			}
		}, [debouncedValue])

		return (
			<Wrapper className={className ? className : ''}>
				<FormRow>
					{label ? (
						<Label variant="body2" component="span">
							{label}
						</Label>
					) : null}
					{labelRight && onLabelRightClick ? (
						<LabelRight
							onClick={onLabelRightClick}
							variant="body2"
							component="span"
						>
							{labelRight}
						</LabelRight>
					) : null}
				</FormRow>
				<InputWrapperField>
					{loading ? (
						<InputLoader>
							<LoadingIndicator size={20} />
						</InputLoader>
					) : null}
					{!!showSearchIcon && (
						<SearchIconWrapper>
							<SVG.MagnifyingGlassGray />
						</SearchIconWrapper>
					)}
					<FormInput
						ref={ref as any} // This is styled-components type issue with forwarding refs
						$error={!!errorLabel}
						$showSearchIcon={!!showSearchIcon}
						{...props}
					/>
					{errorLabel ? (
						<InputErrorMessage variant="body2" component="span">
							{errorLabel}
						</InputErrorMessage>
					) : null}
					<DeleteInput
						style={deleteIconSpring}
						$showDeleteButton={!!showDeleteButton}
					>
						<DeleteInputIcon onClick={onDeleteIconClick} />
					</DeleteInput>
				</InputWrapperField>
			</Wrapper>
		)
	}
)

export default Input
