import * as React from 'react'
import Typography from 'components/Typography'
import styled from 'styled-components'
import { DefaultButton } from 'components/Buttons'
import Input from 'components/Forms/Input'
import Label from 'components/Forms/Label'
import api from 'api'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from 'components/LoadingIndicator'
import logger from 'utils/logger'

const Wrapper = styled.div`
	background: ${(props) => props.theme.colors.grayLight};
	padding: 15px;
	text-align: left;
	position: relative;
`

const Text = styled(Typography)`
	margin-bottom: 18px;
	white-space: pre-line;
`

const Button = styled(DefaultButton)`
	margin-top: 18px;
`

const ResetPassword = () => {
	const [email, setEmail] = React.useState('')
	const [loading, setLoading] = React.useState(false)
	const [isSubmitted, setIsSubmitted] = React.useState(false)
	const { t } = useTranslation()

	const onChange = (e: React.FormEvent<HTMLInputElement>) => {
		if (loading) return
		setEmail(e.currentTarget.value)
	}

	const onClick = () => {
		setLoading(true)
		logger.info('request password reset')
		api
			.requestPasswordReset(email)
			.then(() => logger.info('request password SUCCESS'))
			.catch((err) => {
				logger.error('request password reset FAIL', {
					statusCode: err.response?.status,
					message: err.message,
				})
				logger.newrelicError(err, { type: 'reset password' })
			})
			.finally(() => {
				setIsSubmitted(true)
				setLoading(false)
			})
	}

	return (
		<Wrapper>
			{isSubmitted ? (
				<Text variant="body1" component="p">
					{t('forgot_password_modal_success')}
				</Text>
			) : (
				<>
					<Text variant="body1" component="p">
						{t('forgot_password_modal_text')}
					</Text>

					<Label>{t('forgot_password_modal_label')}</Label>
					<Input type="email" value={email} onChange={onChange} />
					{loading ? (
						<LoadingIndicator />
					) : (
						<Button onClick={onClick}>
							{t('forgot_password_modal_button')}
						</Button>
					)}
				</>
			)}
		</Wrapper>
	)
}

export default ResetPassword
