import * as React from 'react'

const Location = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="20"
			viewBox="0 0 14 20"
			fill="#babbc3"
			{...props}
		>
			<path
				d="M29.956,6.334a6.985,6.985,0,1,0-12.483,6.272c.758,1.473,3.7,6.609,5.263,9.3a1.109,1.109,0,0,0,1.937,0c1.558-2.694,4.484-7.83,5.284-9.3A6.623,6.623,0,0,0,29.956,6.334ZM23.725,12.5a3.031,3.031,0,1,1,3.031-3.031A3.032,3.032,0,0,1,23.725,12.5Z"
				transform="translate(-16.745 -2.478)"
			/>
		</svg>
	)
}

export default Location
