import * as React from 'react'
import styled from 'styled-components'
import Typography from 'components/Typography'

const StyledLabel = styled(Typography)`
	font-weight: bold;
	font-size: 12px;
`

type Props = React.HTMLProps<HTMLLabelElement>

const Label = ({ children, className }: Props) => {
	return (
		<StyledLabel variant="body2" component="label" className={className}>
			{children}
		</StyledLabel>
	)
}

export default Label
