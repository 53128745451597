import * as React from 'react'

type Props = {
	type: 'full' | 'half' | 'blank'
	fillColor?: string
} & React.SVGProps<SVGSVGElement>

const Star = ({ type, fillColor = '#dddde1', ...props }: Props) => {
	const defaultColor = '#dddde1'
	const isHalf = type === 'half'

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20.001"
			height="19.998"
			viewBox="0 0 20.001 19.998"
			{...props}
		>
			<symbol>
				<path
					id="star"
					d="M336.335,420.349a1,1,0,0,0-.839-.67l-5.778-.626-2.359-5.674a1,1,0,0,0-.922-.616h0a1,1,0,0,0-.923.614l-2.377,5.675-5.854.627a1,1,0,0,0-.582,1.72l4.433,4.2-1.236,5.955a1,1,0,0,0,1.5,1.058l5.041-3.057,5.055,3.057a1,1,0,0,0,1.5-1.061l-1.257-5.959,4.352-4.2A1,1,0,0,0,336.335,420.349Z"
					transform="translate(-316.388 -412.763)"
				/>
				<path
					id="star_half"
					d="M336.335,420.349a1,1,0,0,0-.839-.67l-5.778-.626-2.359-5.674a1,1,0,0,0-.922-.616h0a1,1,0,0,0-.923.614l-2.377,5.675-5.854.627a1,1,0,0,0-.582,1.72l4.433,4.2-1.236,5.955a1,1,0,0,0,1.5,1.058l5.041-3.057,5.055,3.057a1,1,0,0,0,1.5-1.061l-1.257-5.959,4.352-4.2A1,1,0,0,0,336.335,420.349Z"
					transform="translate(-316.388 -412.763)"
					style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0% 100%)' }}
				/>
			</symbol>
			<use
				href="#star"
				width="100"
				height="100"
				fill={isHalf ? defaultColor : fillColor}
			/>
			{isHalf && (
				<use href="#star_half" width="100" height="100" fill={fillColor} />
			)}
		</svg>
	)
}

export default Star
