import * as React from 'react'
import CustomModal from 'components/Modal'
import ResetPassword from 'components/Authentication/Login/ResetPassword/modal'
import api from 'api'
import {
	FacebookLoginResponse,
	LoginResponse,
	UpdateUserData,
} from 'api/user.types'
import { Cookies } from 'utils/cookies'
import { setAuthToken } from 'api/http'
import { LoginWithCredentialsResponse } from 'api/__mocks__/mockdata/loginWithCredentialsResponse'
import LoginModalView from 'components/Authentication/Login/login-modal-view'
import GA from 'utils/analytics'
import dataLayer from 'utils/analytics/dataLayer'
import logger from 'utils/logger'
import Modal from 'components/Shared/Modals/modal'
import LoginModal from 'components/Authentication/Login/LoginModal'
import ResetPasswordModal from 'components/Authentication/Login/ResetPasswordModal'

type Props = {
	children: React.ReactNode
}

declare global {
	interface Window {
		isAuthenticated: boolean
	}
}

type AuthContext = {
	promptLogin: (uncloseable?: boolean) => void
	promptLoginNew: () => void
	resetPassword: () => void
	isAuthenticated: boolean
	login: (email: string, password: string) => Promise<LoginResponse>
	logout: () => Promise<void>
	createUser: () => Promise<string>
	refreshUserData: () => Promise<void>
	isAuthenticating: boolean
	facebookLogin: (response: FacebookLoginResponse) => Promise<LoginResponse>
	updateUser: (userId: string, data: Partial<UpdateUserData>) => Promise<void>
	userData: LoginResponse | null
	userId?: string
}

export const AuthContext = React.createContext<AuthContext>({
	promptLogin: () => Promise.resolve(),
	promptLoginNew: () => Promise.resolve(),
	resetPassword: () => null,
	isAuthenticated: false,
	isAuthenticating: true,
	login: () => Promise.resolve(LoginWithCredentialsResponse),
	logout: () => Promise.resolve(),
	refreshUserData: () => Promise.resolve(),
	facebookLogin: () => Promise.resolve(LoginWithCredentialsResponse),
	createUser: () => Promise.resolve(''),
	updateUser: () => Promise.resolve(),
	userData: null,
	userId: undefined,
})

const AuthenticationProvider = (props: Props) => {
	const [userData, setUserData] = React.useState<LoginResponse | null>(null)
	const userId = userData?.user.id
	const isAuthenticated = !!userData && !!userData.user.general.email
	const [isAuthenticating, setIsAuthenticating] = React.useState(true)

	React.useEffect(() => {
		window.isAuthenticated = isAuthenticated
		GA.isAuthenticated = isAuthenticated
	}, [isAuthenticated])

	React.useEffect(() => {
		if (userData) {
			dataLayer.remoteTransactionCount =
				userData.user.customer_transaction_count

			logger.userId = userData.user.id
			logger.userId = userData.user.id
			Cookies.setCookie('user_auth_token', userData.token)
			setAuthToken(userData.token)
			if (!Cookies.getCookieValue('session_exchange_id')) {
				getSessionExchangeToken(userData.user.id)
			}
		}
	}, [userData])

	React.useEffect(() => {
		// Check for existing cookies on mount
		const userId = Cookies.getCookieValue('user_id')
		const authToken = Cookies.getCookieValue('user_auth_token')

		// If we have both a token and userId we attempt to fetch user data
		// If it succeeds we know that the user is authorized
		if (authToken && userId) {
			setAuthToken(authToken)
			setIsAuthenticating(true)
			api
				.getUser(userId)
				.then(setUserData)
				.catch((err) => {
					if (err.response?.status === 401) {
						deleteCookies()
					} else {
						logger.newrelicError(err, { userId })
					}
				})
				.finally(() => setIsAuthenticating(false))

			if (!Cookies.getCookieValue('session_exchange_id')) {
				getSessionExchangeToken(userId)
			}
		} else {
			deleteCookies()
			setIsAuthenticating(false)
		}
	}, [])

	const setCookies = (token: string, userId: string, email: string) => {
		Cookies.setCookie('user_auth_token', token)
		Cookies.setCookie('user_id', userId)
		Cookies.setCookie('user_email', email)
	}

	const deleteCookies = () => {
		Cookies.deleteCookie('user_id')
		Cookies.deleteCookie('user_email')
		Cookies.deleteCookie('user_auth_token')
		Cookies.deleteCookie('session_exchange_id')
	}

	const facebookLogin = (
		response: FacebookLoginResponse
	): Promise<LoginResponse> => {
		return api
			.loginWithFacebook(response.userID, response.accessToken)
			.then((res) => {
				setAuthToken(res.token)
				setCookies(res.token, res.user.id, res.user.general.email)
				onLogin(res)
				setUserData(res)
				return res
			})
	}

	const login = (email: string, password: string) => {
		return api.login(email, password).then((res) => {
			setAuthToken(res.token)
			setCookies(res.token, res.user.id, res.user.general.email)
			onLogin(res)
			setUserData(res)
			return res
		})
	}

	const resetPassword = () => {
		CustomModal.open({
			html: <ResetPassword />,
			titleKey: 'forgot_password_modal_title',
		})
	}

	const resetPasswordNew = () => {
		Modal.open({
			html: <ResetPasswordModal />,
		})
	}

	const onLogin = (response: LoginResponse) => {
		document.dispatchEvent(
			new CustomEvent('user_authenticated', { detail: response })
		)
	}

	const promptLogin = (uncloseable?: boolean) => {
		const params = {
			html: (
				<LoginModalView
					facebookLogin={facebookLogin}
					resetPassword={resetPassword}
					login={login}
				/>
			),
			titleKey: 'login_title',
		}

		if (uncloseable) {
			CustomModal.unclosable(params)
		} else {
			CustomModal.open(params)
		}
	}

	const promptLoginNew = () => {
		Modal.open({
			html: (
				<LoginModal
					facebookLogin={facebookLogin}
					resetPassword={resetPasswordNew}
					login={login}
				/>
			),
		})
	}

	const logout = () => {
		if (userData?.user.general.email) {
			return api
				.logout(userData?.user.general.email)
				.then(() => {
					setAuthToken('')
					setUserData(null)
					deleteCookies()
				})
				.catch((err) => logger.newrelicError(err))
		}

		return Promise.resolve()
	}

	const createUser = async (): Promise<string> => {
		const user = await api.createUser()
		setAuthToken(user.token)
		setCookies(user.token, user.user.id, user.user.general.email)
		setUserData(user)
		return user.user.id
	}

	const refreshUserData = React.useCallback(async () => {
		if (!userId) return
		api
			.getUser(userId)
			.then((user) => setUserData(user))
			.catch((err) => logger.newrelicError(err, { userId }))
		return
	}, [userId])

	const updateUser = (userId: string, data: Partial<UpdateUserData>) => {
		return api.updateUser(userId, data).then(setUserData)
	}

	const getSessionExchangeToken = (userId: string) => {
		api
			.getUserSession(userId)
			.then((res) => {
				Cookies.setCookie('session_exchange_id', res.session_id)
			})
			.catch((err) => {
				logger.newrelicError(err, { userId })
			})
	}

	return (
		<AuthContext.Provider
			value={{
				promptLogin,
				promptLoginNew,
				resetPassword,
				login,
				isAuthenticating,
				facebookLogin,
				createUser,
				updateUser,
				refreshUserData,
				isAuthenticated,
				logout,
				userData,
				userId: userData?.user.id,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	)
}

/**
 * This is used to determine whether a user is authenticated or not when push data onto the dataLayer
 * It will return true if all the right cookies are set
 */
AuthenticationProvider.isAuthenticated = () => {
	return (
		!!Cookies.getCookieValue('user_auth_token') &&
		!!Cookies.getCookieValue('user_id') &&
		!!Cookies.getCookieValue('user_email')
	)
}

export default AuthenticationProvider
