import * as React from 'react'

const Payment = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="24"
			viewBox="0 0 32 24"
			fill="#3a9b2e"
			{...props}
		>
			<g transform="translate(-304.93 -440.653)">
				<path
					d="M304.93,464.576a1.835,1.835,0,0,0,1.829,1.84H335.1a1.835,1.835,0,0,0,1.829-1.84V452.153h-32Zm5.029-7.822H320.93a.92.92,0,0,1,0,1.84H309.959a.92.92,0,0,1,0-1.84Zm0,3.681h7.314a.92.92,0,0,1,0,1.84h-7.314a.92.92,0,0,1,0-1.84Z"
					transform="translate(0 -1.763)"
				/>
				<path
					d="M335.1,440.653H306.759a1.834,1.834,0,0,0-1.829,1.84v4.6h32v-4.6A1.834,1.834,0,0,0,335.1,440.653Z"
					transform="translate(0)"
				/>
			</g>
		</svg>
	)
}

export default Payment
