import * as React from 'react'
import { classNames } from 'utils/classNames'

// Styles for this component are defined in the ./styles.ts file and included in our HungryThemeProvider
// TODO: Make it generic so you can pass any props matching the component element
type Props = {
	component: React.ElementType
	variant:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'body1'
		| 'body2'
		| 'subtitle1'
		| 'subtitle2'
	className?: string
	// TODO: Fix this by making it generic
	/* eslint-disable @typescript-eslint/no-explicit-any */
	onClick?: () => any
	htmlFor?: string
	itemProp?: string
} & React.HTMLAttributes<React.ElementType>

const Typography: React.ComponentType<Props> = ({
	component: Component,
	className,
	variant,
	children,
	...props
}) => {
	return (
		<Component className={classNames(variant, className)} {...props}>
			{children}
		</Component>
	)
}

export default Typography
