import * as React from 'react'
import styled from 'styled-components'
import BaseButtonLayout, { Props as ButtonProps } from './BaseLayout'

const StyledButton = styled(BaseButtonLayout)`
	${(props) => `
    color: ${props.theme.InteractionSecondary};
    background: ${props.theme.InteractionPrimary};
    cursor: pointer;

    &:hover{
      background: ${props.theme.HoverPrimary};
    }
  `}
`

const PrimaryButton = (props: ButtonProps) => {
	return <StyledButton {...props} />
}

export default PrimaryButton
