export const breakpointValues = {
	mobileS: 320,
	mobileM: 375,
	mobileL: 425,
	tabletS: 601,
	tablet: 768,
	laptop: 1024,
	laptopL: 1440,
	desktop: 2560,
}

export const breakpoints = {
	mobileS: `(min-width: ${breakpointValues.mobileS}px)`,
	mobileM: `(min-width: ${breakpointValues.mobileM}px)`,
	mobileL: `(min-width: ${breakpointValues.mobileL}px)`,
	tabletS: `(min-width: ${breakpointValues.tabletS}px)`,
	tablet: `(min-width: ${breakpointValues.tablet}px)`,
	laptop: `(min-width: ${breakpointValues.laptop}px)`,
	laptopL: `(min-width: ${breakpointValues.laptopL}px)`,
	desktop: `(min-width: ${breakpointValues.desktop}px)`,
}
