import * as React from 'react'

const Success = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="29"
			height="29"
			viewBox="0 0 29 29"
			fill="#3a9b2e"
			{...props}
		>
			<g transform="translate(-583.001 -2179.001)">
				<path
					d="M29.325,14.755a14.5,14.5,0,1,1-14.5-14.5,14.5,14.5,0,0,1,14.5,14.5Z"
					transform="translate(582.676 2178.746)"
				/>
				<path
					fill="#fff"
					d="M323.821,463.768a1.34,1.34,0,0,1-.949-.393l-5.977-5.977a1.343,1.343,0,0,1,1.9-1.9l4.738,4.738,6.387-11.112a1.343,1.343,0,0,1,2.33,1.338l-7.263,12.632a1.338,1.338,0,0,1-.987.663A1.3,1.3,0,0,1,323.821,463.768Z"
					transform="translate(272.875 1737.23)"
				/>
			</g>
		</svg>
	)
}

export default Success
