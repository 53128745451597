import * as React from 'react'

const Password = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25.564"
			height="28.909"
			viewBox="0 0 25.564 28.909"
			fill="#3a9b2e"
			{...props}
		>
			<path
				d="M291.531,457.341h-1.542a9.779,9.779,0,0,0-2.542-5.384,9.073,9.073,0,0,0-6.74-2.525h-.03a9.128,9.128,0,0,0-6.776,2.554,9.773,9.773,0,0,0-2.528,5.356h-1.754a1.826,1.826,0,0,0-1.826,1.826v17.347a1.826,1.826,0,0,0,1.826,1.826h21.912a1.825,1.825,0,0,0,1.826-1.826V459.167A1.826,1.826,0,0,0,291.531,457.341Zm-15.661-3.452a6.522,6.522,0,0,1,4.813-1.718h.024a6.464,6.464,0,0,1,4.774,1.695,6.842,6.842,0,0,1,1.727,3.476H274.154A6.821,6.821,0,0,1,275.87,453.889Zm6.073,16.692a1.37,1.37,0,1,1-2.739,0V464.19a1.37,1.37,0,0,1,2.739,0Z"
				transform="translate(-267.793 -449.432)"
			/>
		</svg>
	)
}

export default Password
