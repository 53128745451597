import * as React from 'react'

const Eye = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="14"
			viewBox="0 0 23 14"
			fill="#babbc3"
			{...props}
		>
			<g transform="translate(-310.93 -447.153)">
				<path d="M322.43,461.153c-4.366,0-8.514-2.411-11.381-6.616a.679.679,0,0,1,0-.769c2.867-4.2,7.016-6.615,11.381-6.615s8.514,2.411,11.38,6.616a.676.676,0,0,1,0,.769C330.945,458.742,326.8,461.153,322.43,461.153Zm0-11.2a4.262,4.262,0,0,0-4.313,4.2,4.314,4.314,0,0,0,8.625,0A4.262,4.262,0,0,0,322.43,449.953Z" />
				<circle
					cx="2.5"
					cy="2.5"
					r="2.5"
					transform="translate(319.93 451.653)"
				/>
			</g>
		</svg>
	)
}

export default Eye
