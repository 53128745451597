import { AxiosError } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { SessionStorage } from '../session-storage'
import api from '../../api'

declare global {
	interface Window {
		newrelic?: {
			noticeError: (
				err: Error,
				customAttributes: Record<string, unknown>
			) => void
		}
	}
}

enum LogLevel {
	debug = 0,
	info = 1,
	error = 2,
	critical = 3,
}

export type LogEntry = {
	level: keyof typeof LogLevel
	message: string
	timestamp: number
	properties?: Record<string, any>
}

const maxLogsBeforeSending = 200
const sessionStorageKey = (sessionId: string) => `logs-${sessionId}`

class Logger {
	userId?: string
	sessionId: string
	private logs: LogEntry[]
	private queuedLogs: LogEntry[]
	private queue: Promise<void>
	private logTimeout?: ReturnType<typeof setTimeout>

	constructor() {
		this.sessionId = ''
		if (typeof window !== 'undefined') {
			this.sessionId = SessionStorage.getItem('hungry_session_id')
			if (!this.sessionId) {
				this.sessionId = uuidv4()
				SessionStorage.setItem('hungry_session_id', this.sessionId, 3)
			}
		}

		this.logs = [
			{
				level: 'info',
				timestamp: Date.now(),
				message: 'Instantiate logger',
				properties: {},
			},
		]
		this.queuedLogs = []
		this.queue = Promise.resolve()

		if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
			this.logs = [...this.logs, ...this.retrieveSavedLogs()]
			this.sendLogs().catch((err) =>
				this.error('Failed to send logs', {
					errorMessage: err.message,
				})
			)

			window.addEventListener('beforeunload', this.saveLogs)
		}
	}

	/** Schedule logs to be sent at interval */
	private logScheduler = (timeout = 5000) => {
		if (this.logTimeout) clearTimeout(this.logTimeout)

		this.logTimeout = setTimeout(() => {
			// Send the logs. After the promise is fulfilled or rejected, schedule the next logs
			this.sendLogs()
				.then(() => this.logScheduler(5000))
				.catch(() => this.logScheduler(5000))
		}, timeout)
	}

	/** Save logs to sessionStorage */
	private saveLogs = () => {
		sessionStorage.setItem(
			sessionStorageKey(this.sessionId),
			JSON.stringify(this.queuedLogs.concat(this.logs))
		)
	}

	/** Retrieves saved logs from session storage */
	private retrieveSavedLogs = (): LogEntry[] => {
		const savedLogs = sessionStorage.getItem(sessionStorageKey(this.sessionId))
		if (savedLogs) return JSON.parse(savedLogs)
		return []
	}

	/** Add the logs to be sent to the queue. */
	private enqueue = (promise: () => Promise<void>) => {
		this.queue = this.queue.then(() => promise())
		// After logs are added to the queue to be sent, we reschedule when we log the next time
		this.logScheduler()
		return this.queue
	}

	/** Adds a log entry to the logs array, which will be scheduled and set to our log service */
	private log = (
		level: LogLevel,
		message: string,
		customAttributes?: Record<string, unknown>
	) => {
		if (typeof window === 'undefined' || process.env.NODE_ENV === 'test') return
		if ( //!process.env.NEXT_PUBLIC_API_BASE_URL?.includes("staging") && 
			level <= LogLevel.info ) return

		this.logs.push({
			level: LogLevel[level] as keyof typeof LogLevel,
			timestamp: Date.now(),
			message,
			properties: {
				...customAttributes,
				userId: this.userId,
			},
		})

		if (this.logs.length >= maxLogsBeforeSending) {
			this.sendLogs()
		}
	}

	info = (message: string, customAttributes?: Record<string, unknown>) => {
		this.log(LogLevel.info, message, customAttributes)
	}

	error = (message: string, customAttributes?: Record<string, unknown>) => {
		this.log(LogLevel.error, message, customAttributes)
	}

	critical = (message: string, customAttributes?: Record<string, unknown>) => {
		this.log(LogLevel.critical, message, customAttributes)
	}

	/** Trigger an api request to send currently stored logs to our log service */
	sendLogs = () => {
		if (this.logs.length === 0) return Promise.resolve()
		// Remove logs from the main logs array and place them onto the queuedLogs array
		// This allows us to keep track of which logs are currently being sent, and also allows
		// us to save them if user aborts the session before logs has been sent.
		const logsToSubmit = this.logs.splice(0, maxLogsBeforeSending)
		this.queuedLogs = this.queuedLogs.concat(logsToSubmit)
		return this.enqueue(() => api.submitLogs(this.sessionId, logsToSubmit))
	}

	newrelicError = (
		err: Error | AxiosError,
		customAttributes: Record<string, unknown> = {}
	) => {
		if (!window.newrelic) return
		customAttributes = {
			...customAttributes,
			hungrySessionId: this.sessionId,
		}

		// If this is an axios error we add some custom attributes
		if ('isAxiosError' in err) {
			const [, token] =
				err.config?.headers?.authentication?.split('token=') || []

			customAttributes = {
				code: err.code,
				statusCode: err.response?.status,
				url: err.config.url,
				message: err.message,
				// This might help debug the 401 errors we see logged to new relic
				isRequestSentWithToken: token !== undefined,
				isTokenNullOrEmpty: !token,
				responseHeaders: err.response?.headers,
				asJson: err.toJSON(),
				...customAttributes,
			}
		}

		// Log messages to new relic
		if (window.location.hostname !== 'localhost') {
			window.newrelic.noticeError(err, {
				userId: this.userId,
				websiteVersion: process.env.NEXT_PUBLIC_WEBSITE_VERSION,
				...customAttributes,
			})
		}

		// Log messages to console for localhost and staging
		if (['localhost', 'staging.hungry.dk'].includes(window.location.hostname)) {
			console.log(
				`%c${err.message} - ${customAttributes.url}`,
				'background: #000; color: #FFF; font-size: 16px;'
			)
			console.dir(err)
		}
	}
}

export default new Logger()
