import * as React from 'react'
// @ts-ignore
import FacebookLoginWrapper from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookLoginResponse, LoginResponse } from 'api/user.types'
import { FacebookLoginButton } from 'components/Buttons'

type Props = {
	className?: string
	callback: (data: FacebookLoginResponse) => Promise<void | LoginResponse>
	children: string
}

const FacebookLogin = (props: Props) => {
	return (
		<FacebookLoginWrapper
			buttonStyle={{ width: '100%', margin: '8px 0' }}
			appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
			fields="name,email"
			scope="public_profile,email"
			// If we don't set mobile to false, then the library will use redirect for authenticating through facebook instead of through a popup.
			// We don't want to authenticate via redirect, as that will redirect us to the front page with a query param ?code=xxx
			isMobile={false}
			callback={props.callback}
			render={(renderProps: RenderProps) => (
				<FacebookLoginButton {...renderProps} className={props.className}>
					{props.children}
				</FacebookLoginButton>
			)}
		/>
	)
}

type RenderProps = {
	isDisabled: boolean
	isProcessing: boolean
	isSdkLoaded: boolean
	onClick: (e: React.FormEvent<HTMLButtonElement>) => void
}

export default FacebookLogin
