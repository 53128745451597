import * as React from 'react'

const ArrowBack = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="16"
			viewBox="0 0 10 16"
			fill="#1d2038"
			{...props}
		>
			<path
				d="M14.265,14.192l6.618-6.049a1.071,1.071,0,0,0,0-1.615,1.34,1.34,0,0,0-1.77,0l-7.5,6.854a1.072,1.072,0,0,0-.036,1.577l7.53,6.9a1.342,1.342,0,0,0,1.77,0,1.071,1.071,0,0,0,0-1.615Z"
				transform="translate(-11.251 -6.194)"
			/>
		</svg>
	)
}

export default ArrowBack
