import axios, { AxiosRequestConfig, AxiosInstance } from 'axios'
import { useInterceptors, InterceptorRequestOptions } from './interceptors'
import axiosRetry from 'axios-retry'

export const setupHttpClient = (
	baseUrl: string | undefined,
	config: Omit<AxiosRequestConfig, 'baseURL'> = {},
	interceptorOptions: InterceptorRequestOptions = { addRequestId: true }
): AxiosInstance => {
	const instance = axios.create({
		baseURL: baseUrl,
		...config,
	})

	// Setup interceptors which handles request logging
	useInterceptors(instance, interceptorOptions)

	// Configure retry logic. By default it retries network errors and idempotent requests if it fails with a 5xx error
	axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

	return instance
}
