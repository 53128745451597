import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Shared/Modals/modal'
import ButtonPrimary from 'components/Shared/Buttons/Primary'
import Typography from 'components/Shared/Typography'
import Input from 'components/Shared/Form/Input'
import { breakpoints } from 'styles/breakpoints'
import * as SVG from 'components/SVG'
import logger from 'utils/logger'
import api from 'api'
import * as yup from 'yup'

const schema = yup.object({
	email: yup
		.string()
		.email('profile_details_email_error')
		.required('profile_details_email_error'),
})

const Wrapper = styled.div`
	box-sizing: border-box;
	height: 100%;
	display: flex;
	flex-flow: column;
	padding: 52px 22px 60px 14px;
	@media ${breakpoints.tablet} {
		padding: 44px 60px 40px;
	}
`

const IconPassword = styled(SVG.Password)`
	margin: 0 auto;
	width: 26px;
	height: 29px;
	display: block;
`

const IconSuccess = styled(SVG.Success)`
	margin: 55px auto 0;
	width: 29px;
	height: 29px;
	display: block;
`

const Heading = styled(Typography)`
	margin: 18px auto 8px;
	width: 170px;
`

const Text = styled(Typography)`
	margin: 0 auto 15px;
	width: 270px;
`

const FormWrapper = styled.div`
	display: flex;
	flex-flow: column;
	width: 260px;
	margin: 0 auto;
	& > div {
		margin-bottom: 15px;
	}
	@media ${breakpoints.mobileM} {
		width: 300px;
	}
	@media ${breakpoints.tablet} {
		width: 400px;
	}
`

const LoginButton = styled(ButtonPrimary)`
	align-self: flex-end;
	width: 200px;
	margin: 0 auto;
`

const ButtonWrapper = styled.div`
	height: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
`

const ResetPasswordModal = () => {
	const { t } = useTranslation()
	const [email, setEmail] = React.useState('')
	const [loading, setLoading] = React.useState(false)
	const [isSubmitted, setIsSubmitted] = React.useState(false)
	const [error, setError] = React.useState('')

	const onSubmit = async () => {
		setLoading(true)
		logger.info('request password reset')
		api
			.requestPasswordReset(email)
			.then(() => logger.info('request password SUCCESS'))
			.catch((err) => {
				logger.error('request password reset FAIL', {
					statusCode: err.response?.status,
					message: err.message,
				})
				logger.error(err, { type: 'reset password' })
			})
			.finally(() => {
				setIsSubmitted(true)
				setLoading(false)
			})
	}

	const onChange = (e: React.FormEvent<HTMLInputElement>) => {
		if (loading) return
		const { name, value } = e.currentTarget

		yup
			.reach(schema, name)
			.validate(value)
			.then(() => setError(''))
			.catch((err: any) => {
				setError(err.message)
			})
		setEmail(e.currentTarget.value)
	}

	return (
		<Wrapper>
			{isSubmitted ? (
				<>
					<IconSuccess />
					<Heading component="h2" variant="h2">
						Tak for det!
					</Heading>
					<Text component="p" variant="body">
						Vi har sendt dig en mail med et link til nulstilling af din
						adgangskode.
						<br />
						<br />
						Har du ikke fået et link? Vent et par minutter, tjek dit spamfilter
						eller prøv igen.
					</Text>
					<ButtonWrapper>
						<LoginButton onClick={() => Modal.close()}>Okay</LoginButton>
					</ButtonWrapper>
				</>
			) : (
				<>
					<IconPassword />
					<Heading component="h2" variant="h2">
						Har du glemt din adgangskode?
					</Heading>
					<Text component="p" variant="body">
						Ingen grund til panik! Det kan ske for selv den bedste.
						<br />
						<br />
						Indtast din e-mail, så sender vi dig en mail med et link, som du kan
						bruge til at nulstille din adgangskode.
					</Text>
					<FormWrapper>
						<Input
							onChange={onChange}
							name="email"
							label={t('email')}
							type="email"
							value={email}
							errorLabel={t(error)}
						/>
					</FormWrapper>
					<ButtonWrapper>
						<LoginButton onClick={onSubmit} disabled={!email || !!error}>
							{t('forgot_password_modal_button')}
						</LoginButton>
					</ButtonWrapper>
				</>
			)}
		</Wrapper>
	)
}

export default ResetPasswordModal
