import * as React from 'react'
import styled from 'styled-components'
import DefaultButton from './DefaultButton'
import { breakpoints } from 'styles/breakpoints'

const StyledButton = styled(DefaultButton)`
	background: ${(props) => props.theme.colors.facebook};
	width: 100%;
	color: ${(props) => props.theme.colors.white} !important;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;

	@media ${breakpoints.tablet} {
		height: 40px;
	}
`

const Icon = styled.i`
	font-size: 16px;
	margin-right: 8px;
`

type Props = {
	children: string
	className?: string
	isDisabled: boolean
	onClick: (e: React.FormEvent<HTMLButtonElement>) => void
}

const FacebookLoginButton = ({ children, ...props }: Props) => {
	return (
		<StyledButton {...props}>
			<Icon
				className="fab fa-facebook-square facebook-login-icon"
				color="#FFF"
			/>
			{children}
		</StyledButton>
	)
}

export default FacebookLoginButton
