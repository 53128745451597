import * as React from 'react'
import styled from 'styled-components'
import { breakpoints, breakpointValues } from 'styles/breakpoints'
import * as SVG from 'components/SVG'

type Props = {
	onClose?: () => void
	children: React.ReactNode
	minWidth?: number
	minHeight?: number
	maxWidth?: number
}

const Wrapper = styled.div<{
	minWidth: number
	minHeight: number
	maxWidth: number
}>`
	margin: auto;
	position: relative;
	min-height: 616px;
	max-width: ${(props) => props.maxWidth}px;
	display: inline-flex;
	width: 100%;
	border-radius: 5px;

	@media (max-height: 610px) {
		min-height: 96vh;
	}
	@media ${breakpoints.tablet} {
		min-height: ${(props) => props.minHeight}px;
		min-width: ${(props) => props.minWidth}px;
	}

	@media (max-width: 810px) and (min-width: ${breakpointValues.tablet}px) {
		min-width: 750px;
	}
`

const Content = styled.div`
	width: 100%;
	height: inherit;
	max-height: inherit;
`

export const CloseButton = styled(SVG.Close)`
	color: ${(props) => props.theme.InteractionSecondary};
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	z-index: ${(props) => props.theme.zIndex.MODAL_CLOSE_BUTTON};
`

const ModalBaseLayout = ({
	minWidth = 790,
	minHeight = 500,
	maxWidth = 790,
	...props
}: Props) => {
	return (
		<Wrapper minWidth={minWidth} minHeight={minHeight} maxWidth={maxWidth}>
			{props.onClose && <CloseButton onClick={props.onClose} />}
			<Content>{props.children}</Content>
		</Wrapper>
	)
}

export default ModalBaseLayout
