import * as React from 'react'
import * as Icon from 'components/Icon'
import styled from 'styled-components'

type Props = {
	children: React.ReactNode
}

const Wrapper = styled.div`
	background: ${(props) => props.theme.colors.white};
`

const Header = styled.div`
	background: ${(props) => props.theme.colors.gray};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
`

const Content = styled.div`
	padding: 24px;
`

const ExclamationMark = styled(Icon.ExclamationCircle)`
	font-size: 60px;
	color: ${(props) => props.theme.colors.white};
`

const BaseError = (props: Props) => {
	return (
		<Wrapper>
			<Header>
				<ExclamationMark />
			</Header>
			<Content>{props.children}</Content>
		</Wrapper>
	)
}

export default BaseError
