import { PageInfo, Category, User, PageType } from './dataLayer.types'
import { isMobile } from './isMobile'
import AuthenticationProvider from 'providers/Authentication'

declare global {
	interface Window {
		dataLayer?: {
			conversion_value?: number
			conversion_currency?: 'DKK'
			page?: [
				{
					pageInfo: [PageInfo]
					category: [Category]
					user: [User]
				}
			]
		}[]
	}
}

export type AdditionalData =
	| {
			conversion_value: number
			conversion_currency: 'DKK'
	  }
	| Record<string, unknown>

export type DataLayerEvent =
	| {
			action: string
			category: string
			label?: string
			nonInteraction?: boolean
	  }
	| Record<string, unknown>

class DataLayer {
	remoteTransactionCount: number

	constructor() {
		// We set this once a user is logged in
		this.remoteTransactionCount = 0
	}

	defaultPush(data: AdditionalData = {}) {
		if (!document) return
		if (!window.dataLayer) {
			window.dataLayer = []
		}

		window.dataLayer.push({
			...data,
		})
	}

	push(pageType: PageType, data: AdditionalData = {}) {
		if (!document) return
		if (!window.dataLayer) {
			window.dataLayer = []
		}

		const transactionCount = Math.max(
			this.remoteTransactionCount,
			Number(localStorage.getItem('transactionCount') || '0')
		)

		window.dataLayer.push({
			...data,
			page: [
				{
					pageInfo: [
						{
							pageID: '',
							pageName: document.title,
							destinationURL: window.location.href,
							referringURL: document.referrer,
							sysEnv: isMobile ? 'mobile' : 'desktop',
						},
					],
					category: [
						{
							pageType,
						},
					],
					user: [
						{
							sessions: localStorage.getItem('return_count') || '1',
							transactions: transactionCount,
							loggedIn: AuthenticationProvider.isAuthenticated(),
						},
					],
				},
			],
		})
	}
}

export default new DataLayer()
