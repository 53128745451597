import * as React from 'react'
import { DefaultButton } from 'components/Buttons'
import { useTranslation } from 'react-i18next'
import Typography from 'components/Typography'
import Modal from 'components/Modal'
import styled from 'styled-components'
import BaseError from './base-error'

type Props = {
	errors: string[]
}

const Title = styled(Typography)`
	margin-bottom: 12px;
`

const Button = styled(DefaultButton)`
	margin-top: 18px;
`

const Text = styled(Typography)`
	white-space: pre-wrap;
`

const ErrorModal = (props: Props) => {
	const { t } = useTranslation()

	return (
		<BaseError>
			<Title variant="h6" component="h1">
				{t('modal_generic_error_title')}
			</Title>
			{props.errors.map((error) => (
				<Text component="p" variant="body1" key={error}>
					{error}
				</Text>
			))}
			<Button onClick={() => Modal.close()}>
				{t('modal_generic_error_confirm')}
			</Button>
		</BaseError>
	)
}

export default ErrorModal
