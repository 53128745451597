import { createGlobalStyle } from 'styled-components'

export const TypographyStyles = createGlobalStyle`
	${(props) => `
		.new-h1{
			font-family: ${props.theme.H1FontFamily};
			font-size: ${props.theme.H1FontSize};
			font-weight: ${props.theme.H1FontWeight};
			// line-height: ${props.theme.H1LineHeight};
			line-height: 36px;
			color: ${props.theme.H1Color};
			box-sizing: border-box;
		}
		.new-h2{
			font-family: ${props.theme.H2FontFamily};
			font-size: ${props.theme.H2FontSize};
			font-weight: ${props.theme.H2FontWeight};
			// line-height: ${props.theme.H2LineHeight};
			line-height: 30px;
			color: ${props.theme.H2Color};
			box-sizing: border-box;
		}
		.new-h3{
			font-family: ${props.theme.H3FontFamily};
			font-size: ${props.theme.H3FontSize};
			font-weight: ${props.theme.H3FontWeight};
			// line-height: ${props.theme.H3LineHeight};
			line-height: 22px;
			color: ${props.theme.H3Color};
			box-sizing: border-box;
		}
		.new-h4{
			font-family: ${props.theme.H4FontFamily};
			font-size: ${props.theme.H4FontSize};
			font-weight: ${props.theme.H4FontWeight};
			// line-height: ${props.theme.H4LineHeight};
			line-height: 22px;
			color: ${props.theme.H4Color};
			box-sizing: border-box;
		}
		.new-body{
			font-family: ${props.theme.ContentFontFamily};
			font-size: ${props.theme.ContentFontSize};
			font-weight: ${props.theme.ContentFontWeight};
			// line-height: ${props.theme.ContentLineHeight};
			line-height: 20px;
			color: ${props.theme.ContentColor};
			box-sizing: border-box;
		}
		.new-helperText{
			font-family: ${props.theme.HelpertextFontFamily};
			font-size: ${props.theme.HelpertextFontSize};
			font-weight: ${props.theme.HelpertextFontWeight};
			// line-height: ${props.theme.HelpertextLineHeight};
			line-height: 16px;
			color: ${props.theme.HelpertextColor};
			box-sizing: border-box;
		}
	`}
`
