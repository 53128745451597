import React from 'react'
import { classNames } from 'utils/classNames'

const FAIcon = (iconName: string) =>
	function Icon({ className, ...props }: React.HTMLAttributes<HTMLElement>) {
		return <i className={classNames(iconName, className)} {...props} />
	}

export const MapMarker = FAIcon('fas fa-map-marker-alt')
export const ShoppingCart = FAIcon('fas fa-shopping-cart')
export const LocationArrow = FAIcon('fas fa-location-arrow')
export const Plus = FAIcon('fas fa-plus')
export const Minus = FAIcon('fas fa-minus')
export const ChevronDown = FAIcon('fas fa-chevron-down')
export const ChevronUp = FAIcon('fas fa-chevron-up')
export const ChevronLeft = FAIcon('fas fa-chevron-left')
export const ChevronRight = FAIcon('fas fa-chevron-right')
export const ForkAndKnife = FAIcon('fas fa-utensils')
export const Close = FAIcon('fas fa-times')
export const Check = FAIcon('fas fa-check')
export const Pencil = FAIcon('fas fa-pencil-alt')
export const ExclamationCircle = FAIcon('fas fa-exclamation-circle')
export const StarSolid = FAIcon('fas fa-star')
export const Filter = FAIcon('fas fa-filter')
